import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/ro'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Totul este în ordine!',
  'side-effect.successful-message': 'Datele au fost salvate corect',
  'side-effect.internal-error-title': 'Ceva nu a mers bine',
  'side-effect.internal-error-message': 'Vă rugăm încercați mai târziu sau verificați conexiunea la internet',

  'monday-sshort-label': 'Lu',
  'tuesday-sshort-label': 'Ma',
  'wednesday-sshort-label': 'Mi',
  'thursday-sshort-label': 'Jo',
  'friday-sshort-label': 'Vi',
  'saturday-sshort-label': 'Sâ',
  'sunday-sshort-label': 'Du',

  'monday-short-label': 'Lun',
  'tuesday-short-label': 'Mar',
  'wednesday-short-label': 'Mie',
  'thursday-short-label': 'Joi',
  'friday-short-label': 'Vin',
  'saturday-short-label': 'Sâm',
  'sunday-short-label': 'Dum',

  'monday-label': 'Luni',
  'tuesday-label': 'Marți',
  'wednesday-label': 'Miercuri',
  'thursday-label': 'Joi',
  'friday-label': 'Vineri',
  'saturday-label': 'Sâmbătă',
  'sunday-label': 'Duminică',

  'january-label': 'Ianuarie',
  'february-label': 'Februarie',
  'march-label': 'Martie',
  'april-label': 'Aprilie',
  'may-label': 'Mai',
  'june-label': 'Iunie',
  'july-label': 'Iulie',
  'august-label': 'August',
  'september-label': 'Septembrie',
  'october-label': 'Octombrie',
  'november-label': 'Noiembrie',
  'december-label': 'Decembrie',

  'interval-name-year': 'an',
  'interval-name-month': 'lună',
  'interval-name-week': 'săptămână',
  'interval-name-day': 'zi',
  'interval-name-years': 'ani',
  'interval-name-months': 'luni',
  'interval-name-weeks': 'săptămâni',
  'interval-name-days': 'zile',

  'select-input.no-options-label': 'Fără opțiuni',
  'filter-input.label': 'Filtru:',

  'no-data.label': 'Nu există date de afișat. Schimbați filtrele sau data selectată.',
  'no-data.label-mid': 'Nu există date de afișat. Schimbați data selectată.',
  'no-data.label-short': 'Nu există date de afișat.',
  'empty-list-label': 'Listă goală.',
  'overlay.empty-device-label': 'Niciun dispozitiv nu a fost adăugat acestui profil încă.',
  'overlay.empty-profiles-label': 'Niciun profil adăugat încă.',
  'overlay.empty-device.add-device-label': 'Adaugă dispozitiv',

  'confirmation-modal.cancel-button': 'Anulează',
  'confirmation-modal.confirm-button': 'CONFIRMĂ',
  'confirmation-modal.button-yes': 'Da',
  'confirmation-modal.button-no': 'Nu',

  // Date Picker
  'date-picker.select-date-label': 'Selectați data',

  // Date Filter
  'date-filter.select-label': 'Selectați intervalul',
  'date-filter.this-month': 'Luna aceasta',
  'date-filter.this.week': 'Săptămâna aceasta',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Selectați ziua',
  'date-filter.from-label': 'De la: ',
  'date-filter.to-label': 'Până la: ',

  // Time Picker
  'time-picker.unlimied-label': 'Nelimitat',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Adaugă bonus de timp numai pentru astăzi',
  'time-picker.no-bonus': 'Fără bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Setează bonus',
  'time-picker.add-bonus-button-cancel': 'Anulează',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Interval de date',
  'profile-image-picker.girl-label': 'Fată',
  'profile-image-picker.boy-label': 'Băiat',

  // Profile List
  'profile-list.remove-device-label': 'Eliminați dispozitivul',
  'profile-list.move-device-label': 'Mutați dispozitivul la alt profil',
  'profile-list.add-device-label': 'Adaugați dispozitiv',

  'profile-list.add-profile-label': 'Adaugați profil',
  'profile-list.edit-profile-label': 'Editați profilul',
  'profile-list.add-devices-label': 'Adaugați un dispozitiv nou',
  'profile-list.remove-profile-label': 'Ștergeți profilul',
  'profile-list.remove-profile-disabled-tooltip': 'Pentru a șterge profilul, trebuie mai întâi să eliminați toate dispozitivele atașate profilului.',
  'profile-list.title': 'Lista de profile',

  // Profile Form
  'profile-form.full-name-label': 'Nume',
  'profile-form.month-of-birth-label': 'Luna nașterii',
  'profile-form.year-of-birth-label': 'Anul nașterii',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Anulează',
  'profile-form-modal.create.submit-button': 'ADAUGĂ PROFIL',
  'profile-form-modal.create.title': 'Adăugați profil copil',
  'profile-form-modal.edit.skip-button': 'Anulează',
  'profile-form-modal.edit.submit-button': 'SALVEAZĂ',
  'profile-form-modal.edit.title': 'Editează profil copil',
  'profile-form-modal.max-profiles-error': 'Limita de profil atinsă',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Mutați dispozitivul în alt profil',
  'profile-move-device-modal.cancel-button': 'Anulare',
  'profile-move-device-modal.submit-button': 'Salvează',
  'profile-move-device-modal.select-profile-label': 'Alege profilul',

  // CallLog
  'call-log.duration-label': 'Durata apelului:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'ADAUGĂ PROFIL',
  'create-profile-page.skip-button': 'Anulare',
  'create-profile-page.title': 'Adaugă profil de copil',
  'create-profile-page.description': 'Cine va fi monitorizat',

  // Add device page
  'add-device-page.close-button': 'ÎNCHIDE',
  'add-device-page.description': 'Alegeți tipul de dispozitiv pentru a instala aplicația',
  'add-device-page.title': 'Ghid de instalare',
  'add-device-page.check': 'Verificați manualul',
  'add-device-page.mobile-title': 'Smartphone sau tabletă Android',
  'add-device-page.mobile-description': 'Versiunea Android 6 sau mai mare',
  'add-device-page.desktop-title': 'Desktop sau laptop Windows',
  'add-device-page.desktop-description': 'Versiunea Windows 7 sau mai mare',


  // Purchase Page
  'purchase-page.skip-label': 'Mergi la tabloul de bord',
  'purchase-page.title': 'Alegeți cel mai bun plan',
  'purchase-page.title.features': 'Toate planurile oferă următoarele funcții',
  'purchase-page.title.reviews': 'De ce ne iubesc utilizatorii noștri',
  'purchase-page.subtitle': 'Dacă programul nu îndeplinește așteptările dvs., vă vom returna plata',
  'purchase-page.plan-highlight': 'Cel mai bun raport calitate-preț',
  'purchase-page.monthly': 'lunar',
  'purchase-page.select': 'Alege',
  'purchase-page.close': 'Închide',
  'purchase-page.money-back.title': 'Fără riscuri',
  'purchase-page.money-back.desc': 'Dacă nu ești mulțumit, vei fi rambursat.',
  'purchase-page.header.check-1': 'Cea mai bună aplicație de control parental',
  'purchase-page.header.check-2': 'Stabiliți limita de timp pentru jocuri, YouTube și site-uri web',
  'purchase-page.header.check-3': 'Sute de mii de clienți mulțumiți',
  'purchase-page.plan.year': 'an',
  'purchase-page.plan.optimal': 'Cel mai bun raport calitate-preț',


  'purchase-page.modal.2y-header': 'Ofertă specială!',
  'purchase-page.modal.2y-sub-header': 'Alegeți planul <b>pe 2 ani și economisiți</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Alegeți planul <b>pe 2 ani și economisiți</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Protejează până la {devices} dispozitive',
  'purchase-page.modal.upgrade': 'Alegeți reducerea',
  'purchase-page.modal.no-thanks': 'Continuați cu planul pe 1 an',


  'purchase-plan.features.title.feature_1': 'Limită de timp pentru aplicații și jocuri',
  'purchase-plan.features.title.feature_2': 'Filtrarea site-urilor web',
  'purchase-plan.features.title.feature_3': 'Blocare aplicații, jocuri și site-uri web',
  'purchase-plan.features.title.feature_4': 'Monitorizarea Youtube',
  'purchase-plan.features.title.feature_5': 'Vizualizare live a ecranului de la distanță',
  'purchase-plan.features.title.feature_6': 'Acces la distanță',
  'purchase-plan.features.title.feature_7': 'Rapoarte detaliate',
  'purchase-plan.features.title.feature_8': 'Locația (doar telefon)',
  'purchase-plan.features.title.feature_9': 'Verificați mesajele și apelurile',
  'purchase-plan.features.title.feature_10': 'Suport tehnic de prioritate',

  'purchase-plan.features.desc.feature_1': 'Setați limite de timp pentru aplicații, jocuri și accesul la internet. În plus, programați orele la care copilul dvs. are permis să le acceseze',
  'purchase-plan.features.desc.feature_2': 'Toate site-urile web inadecvate sunt blocate. Programul folosește un algoritm care analizează conținutul site-ului web pentru orice material nepotrivit. De asemenea, colectează informații dintr-o imensă colecție de baze de date a site-urilor web și cuvintelor inadecvate, ambele fiind actualizate în mod regulat.',
  'purchase-plan.features.desc.feature_3': 'Blocați aplicațiile și site-urile web care ar putea avea un impact negativ asupra copiilor dvs. De asemenea, orice joc nou instalat va necesita autorizarea dvs.',
  'purchase-plan.features.desc.feature_4': 'În tabloul de bord, monitorizați toate videoclipurile pe care copilul dvs. le urmărește pe YouTube. Blocați orice videoclip sau blocați complet accesul la serviciile video.',
  'purchase-plan.features.desc.feature_5': 'Accesați de la distanță o transmisie live a ecranului copilului dvs. și verificați activitatea lor pe parcursul zilei. Acest lucru permite monitorizarea atentă a conținutului la care copilul dvs. are acces.',
  'purchase-plan.features.desc.feature_6': 'Odată ce v-ați autentificat, este posibil să accesați panoul de control al părintelui de oriunde și de pe orice dispozitiv. În panoul de bord, ajustați setările, monitorizați dispozitivele copiilor și stabiliți limite de timp.',
  'purchase-plan.features.desc.feature_7': 'În tabloul de bord al părintelui, monitorizați cu ușurință activitatea unui copil pe computerul sau telefonul lor, vedeți cât timp de ecran au avut, ce site-uri web au vizitat și multe altele.',
  'purchase-plan.features.desc.feature_8': 'Verificați locația actuală a copilului dvs. și locațiile trecute pe hartă. Vedeți exact unde a fost copilul dvs. într-o anumită zi sau la un anumit moment.',
  'purchase-plan.features.desc.feature_9': 'Puteți verifica cu ușurință mesajele trimise și primite de copilul dvs., apelurile telefonice și contactele salvate, utilizând panoul părintelui.',
  'purchase-plan.features.desc.feature_10': 'În cazul în care aveți nevoie de ajutor, suntem întotdeauna disponibili. De asemenea, există multe ghiduri video pentru a vă ajuta să utilizați {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Foarte eficient! Protejează complet copilul meu de conținutul dăunător de pe internet.',
  'purchase-plan.raitings.desc.raiting_2': 'Exact ce căutam! Am setat o limită zilnică de o oră pentru fiicele mele de a accesa internetul și Avosmart are grijă de asta pentru mine.',
  'purchase-plan.raitings.desc.raiting_3': 'Recomand Avosmart deoarece are o varietate de funcții. Copiii mei sunt protejați atunci când folosesc internetul și eu pot controla cât timp petrec jucându-se la jocuri, etc.',
  'purchase-plan.raitings.desc.raiting_4': 'A fost important pentru soția mea și pentru mine să putem ține sub observație obiceiurile fiicei noastre pe rețelele de socializare. Avosmart ne ajută să menținem un echilibru între viața digitală și cea reală a fiicei noastre.',
  'purchase-plan.raitings.desc.raiting_5': 'Fiul meu obișnuia să petreacă prea mult timp pe internet, folosind un laptop, tabletă, etc.. Cu rapoartele furnizate de Avosmart, știu exact ce site-uri vizitează fiul meu și cât timp petrece pe ele.',
  'purchase-plan.raitings.desc.raiting_6': 'Acum pot verifica oricând locația actuală a fiului meu. Un serviciu excelent!',


  'account-settings-page.title': 'Setări cont',
  'account-settings-page.automatic-update': 'Actualizări automate ale aplicației Windows',
  'account-settings-page.timezone': 'Fus orar : ',
  'account-settings-page.send-reports': 'Trimite rapoarte de activitate zilnice',


  'plans-info-page.title': 'Informații abonament',
  'plans-info-page.plan': ' Plan:',
  'plans-info-page.max-devices': 'Număr maxim de dispozitive:',
  'plans-info-page.valid-till': 'Data expirării:',

  'plans-info-page.your-plan': 'Planul tău:',
  'plans-info-page.subscription-information': 'Informații abonament',
  'plans-info-page.subscription-created': 'Abonament creat: ',
  'plans-info-page.subscription-current-period': 'Perioada curentă de abonament: ',
  'plans-info-page.subscription-renews-on': 'Planul tău se reînnoiește pe ',
  'plans-info-page.subscription-cancel-renewal': 'Anulează reînnoirea',
  'plans-info-page.subscription-not-renewed': 'Planul tău NU va fi reînnoit la sfârșitul perioadei curente',
  'plans-info-page.subscription-enable-renewal': 'Activează reînnoirea',
  'plans-info-page.subscription-price': 'Preț abonament: ',
  'plans-info-page.subscription-price-amount': '{amount} la fiecare {period}',


  'plans-info-page.upgrade-plan': 'Îmbunătățește-ți planul',
  'plans-info-page.extend-lic': 'Prelungește-ți planul',
  'plans-info-page.subscriptions-portal': 'Administrează metodele tale de plată',
  'plans-info-page.recent-payments': 'Ultimele tranzacții',
  'plans-info-page.date-of-payment': 'Data tranzacției:',
  'plans-info-page.transaction-status': 'Starea tranzacției:',
  'plans-info-page.no-recent-payments': 'Nu există tranzacții recente',
  'plans-info-page.btn-close': 'Închide',
  'plans-info-page.plan-devices': 'până la {devices} dispozitive',
  'plans-info-page.plan-devices-types': 'computere/tablete/telefoane',


  'upgrade-page.title': 'Actualizează planul',
  'upgrade-page.devices': 'Dispozitive: ',
  'upgrade-page.valid-till': 'Planul tău este valabil până la: ',
  'upgrade-page.confirm-upgrade': 'Planul tău va fi îmbunătățit, vei fi taxat {amount}',
  'upgrade-page.pay-now': 'Plătește acum {amount}',
  'upgrade-page.pay-then': 'Apoi {amount} la fiecare {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Cumpără abonament',
  'purchase-checkout.up-to-devices': 'Până la {devices} dispozitive',
  'purchase-checkout.subscribtion-price': '{amount} la fiecare {period}',
  'purchase-checkout.subscribe': 'Abonează-te',
  'purchase-checkout.cancel': 'Anulează',


  // Purchase Status Page
  'purchase-status-page.title': 'Verificare plată',
  'purchase-status-page.status-pending': 'În așteptare',
  'purchase-status-page.status-completed': 'Completat',
  'purchase-status-page.status-canceled': 'Anulat',
  'purchase-status-page.status-refund-finalized': 'Rambursare finalizată',
  'purchase-status-page.status-wating-for-conf': 'Așteptare confirmare',
  'purchase-status-page.status-created': 'Creat',
  'purchase-status-page.status-unkown': 'Necunoscut',
  'purchase-status-page.status-error': 'Eroare',
  'purchase-status-pag.back-button': 'Înapoi la pagina de start',
  'purchase-status-pag.invoice-link': 'Doresc să primesc o factură cu TVA',

  // Login Page
  'login-page.brandline': 'Autentificare',
  'login-page.brandline2': 'Filtrare site-uri web',
  'login-page.email-label': 'adresă e-mail',
  'login-page.login-button': 'AUTENTIFICARE',
  'login-page.password-label': 'Parolă',
  'login-page.recovery-link': 'Ai uitat parola?',
  'login-page.invalid-login-error': 'Adresa de e-mail sau parola pe care le-ai introdus nu sunt valide',

  // Register Page
  'register-page.create-account-label': 'Ai nevoie de un cont? Creează un cont nou',
  'register-page.description': 'Creează-ți contul gratuit',
  'register-page.email-label': 'Adresă e-mail',
  'register-page.login-link-label': 'Ai un cont? Conectează-te',
  'register-page.marketing-checkbox-label': 'Sunt de acord să primesc materiale de marketing și promoționale',
  'register-page.password-label': 'Parola',
  'register-page.phoneNumber-label': 'Număr de telefon',
  'register-page.terms-privacy-checkbox-label': 'Sunt de acord cu politica de confidențialitate și termenii de utilizare',
  'register-page.register-button': 'ÎNREGISTRARE',
  'register-page.register-success-title': 'Succes',
  'register-page.register-success-message': 'Înregistrare reușită. Verifică-ți cutia poștală pentru a confirma emailul',
  'register-page.register-error-captcha': 'Eroare la Captcha. Încearcă din nou.',
  'register-page.register-error-already-registered-label': 'Deja înregistrat. Încearcă să te conectezi sau amintește-ți parola.',
  'register-page.register-error-password-to-short-label': 'Parola prea scurtă.',

  // Forgot Password Page
  'register-page.strong-pass': 'Parola ta este prea simplă și poate fi ușor descifrată de alte persoane. Parola ta ar trebui să conțină litere mari și mici, precum și numere, și ar trebui să aibă cel puțin 8 caractere. Ești sigur că vrei să folosești o parolă simplă?',
  'forgot-password-page.headline-title': 'Reamintește parola',
  'forgot-password-page.email-label': 'Adresa de e-mail',
  'forgot-password-page.reset-button': 'REAMINTEȘTE PAROLA',
  'forgot-password-page.login-link-label': 'Înapoi la pagina de conectare',
  'forgot-password-page.success-title': 'Resetare parolă',
  'forgot-password-page.success-message': 'Verifică-ți cutia poștală pentru instrucțiuni despre cum să îți resetezi parola',
  'forgot-password-page.email-not-confirmed': 'Adresa ta de e-mail nu este verificată. Resetarea parolei nu este disponibilă.',

  // Update Password Page
  'update-password-page.headline-title': 'Setarea unei noi parole',
  'update-password-page.warning-title': 'NOTĂ:',
  'update-password-page.warning-description': 'Stabilirea unei noi parole va rezulta în pierderea datelor de localizare salvate anterior. Restul datelor nu vor fi pierdute.',
  'update-password-page.warning-agreement': 'Înțeleg și accept pierderea datelor de localizare.',
  'update-password-page.new-password': 'Parolă nouă:',
  'update-password-page.new-password-retype': 'Repetă parola nouă:',
  'update-password-page.submit': 'Setează o nouă parolă',
  'update-password-page.alert-weak-password': 'Parola ta este prea simplă și poate fi ușor ghicită de persoane neautorizate. Parola ar trebui să conțină litere mari, litere mici, cifre și să aibă cel puțin 8 caractere. Dorești totuși să folosești o parolă simplă?',
  'update-password-page.alert-agreement': 'Este necesară acceptarea pierderii datelor.',
  'update-password-page.error-message': 'Eroare! Setarea unei noi parole a eșuat.',
  'update-password-page.success-message': 'Noua parolă a fost setată',
  'update-password-page.unknow-error-message': 'Ceva a mers prost',
  'update-password-page.error-link': 'Link eronat',
  'update-password-page.error-posswords-not-equal': 'Parolele nu coincid',
  'update-password-page.error-posswords-length': 'Parolele sunt prea scurte',
  'update-password-page.error-wrong-code': 'Cod greșit',
  'update-password-page.register-success-title': 'Succes!',
  'update-password-page.register-success-message': 'Noua parolă a fost setată.',

  // Error Page
  'not-found-page.title': 'Oops... se pare că a apărut o eroare!',
  'not-found-page.error-code-text': 'Cod eroare',
  'not-found-page.go-back-home-button': 'ÎNAPOI ACASĂ',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Planul tău se încheie la {date}. Te rugăm să iei în considerare achiziționarea unui abonament astăzi',
  'panel-page.purchase-demo-alert': 'În prezent folosești versiunea de încercare.',
  'panel-page.purchase-expired-alert': 'În prezent folosești o versiune de încercare expirată. Cumpără abonamente pentru a continua',
  'panel-page.purchase-lic-expired-alert': 'Planul tău de abonament a expirat. Cumpără abonamente pentru a continua',
  'panel-page.email-alert': 'Nu ai confirmat adresa ta de e-mail',
  'panel-page.button-email-resend': 'Retrimite e-mailul',
  'panel-page.email-alert-resend': ' sau trimite e-mailul din nou.',
  'panel-page.email-alert-resend-title': 'Confirmare',
  'panel-page.email-alert-resend-msg': 'E-mailul a fost trimis!',
  'panel-page.profile-delete-description': 'Ești sigur că vrei să ștergi profilul selectat?',
  'panel-page.profile-delete-device-description': 'Sunteți sigur că doriți să ștergeți dispozitivul selectat? Datele vor fi pierdute.',
  'panel-page.profile-delete-device-second-description': 'Protecția de pe dispozitiv va fi dezactivată. Sunteți sigur că doriți să continuați?',

  'panel-page.email-confirmation-overlay-close': 'Închide',
  'panel-page.email-confirmation-overlay-postpone': 'Mai târziu',
  'panel-page.email-confirmation-overlay-resend': 'Retrimiteți e-mailul',
  'panel-page.email-confirmation-overlay-header': 'Adresa dvs. de e-mail de mai jos nu a fost confirmată',
  'panel-page.email-confirmation-overlay-desc': "Dacă ați făcut o greșeală cu adresa de e-mail de mai sus, vă rugăm să ne anunțați imediat.  <br>Dacă nu ați primit e-mailul, vă rugăm să faceți clic pe \'retrimite\' și să verificați celelalte foldere de e-mail ale dvs. (inclusiv junk/spam).",

  'panel-page.email-confirmation-code-success-header': 'Mulțumesc',
  'panel-page.email-confirmation-code-success-desc': 'E-mailul a fost confirmat!',
  'panel-page.email-confirmation-code-header': 'Vă rugăm să introduceți codul de confirmare de 6 cifre care <br> a fost trimis la adresa dvs. de e-mail.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Codul introdus nu este valid',
  'panel-page.email-confirmation-code-error': 'S-a produs o eroare neașteptată la validarea codului',

  // Summary Page
  'summary-page.title': 'Tablou de bord',
  'summary-page.top-apps.title': 'Aplicații de top',
  'summary-page.top-blocked.title': 'Cele mai blocate',
  'summary-page.top-domains.title': 'Cele mai vizitate pagini web',
  'summary-page.top-search.title': 'Căutări de top',
  'summary-page.access-disable': 'Dezactivare temporară',
  'internet-page.access-time-limit-set-label': 'Limita zilnică pentru accesul la internet:',
  'internet-page.access-time-limit-description': 'Setați limite de timp zilnice pentru fiecare dispozitiv și alegeți câte ore de utilizare a internetului sunt permise.',
  'disable-access.protection-enabled': 'Protecția este activă',
  'disable-access.protection-disabled-for': 'Protecția este dezactivată temporar, {time} minute rămase',
  'disable-access.device-offline': 'Dispozitiv indisponibil',
  'disable-access.protection-status': 'Starea protecției',


  // Internet Page
  'internet-page.headline-title': 'Pagini web',
  'internet-page.access-time-page-title': 'Timp de acces',
  'internet-page.settings-page-title': 'Setări',
  'internet-page.statistics-page-title': 'Statistici',
  'internet-page.searches-page-title': 'Căutări',

  'internet-page.statistics-page-www-ok': 'Website aprobat',
  'internet-page.statistics-page-www-ok-white': 'Website sigur',
  'internet-page.statistics-page-www-timelimit': 'Website blocat datorită limitelor de timp',
  'internet-page.statistics-page-www-userdef': 'Website blocat, definiții utilizator',
  'internet-page.statistics-page-www-content': 'Website blocat, conținut inadecvat',


  'internet-page.white-black-list-page-title': 'Site-uri web sigure / blocate',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtre',
  'internet-page.settings-page.white-list-only-label': 'Permite accesul doar la site-urile de pe lista sigură',
  'internet-page.settings-page.block-erotic-label': 'Blochează accesul la site-urile erotice',
  'internet-page.settings-page.block-offensive-label': 'Blochează accesul la site-urile ofensive, agresive',
  'internet-page.settings-page.block-file-sites-label': 'Blochează accesul la paginile care permit descărcarea de fișiere de pe internet',
  'internet-page.settings-page.block-video-label': 'Blochează accesul la portaluri video',
  'internet-page.settings-page.block-social-label': 'Blochează accesul la rețele sociale',
  'internet-page.settings-page.block-doc-label': 'Blochează descărcarea documentelor',
  'internet-page.settings-page.block-exe-label': 'Blochează descărcarea programelor',
  'internet-page.settings-page.excludeYT-label': 'Nu forța căutarea sigură pe YouTube',
  'internet-page.settings-page.block-new-apps': 'Blochează aplicațiile noi',
  'internet-page.settings-page.block-settings': 'Blochează accesul la setări',
  'internet-page.settings-page.custom-msg.card-tile': 'Mesaj pentru paginile blocate',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Utilizează mesaj personalizat pentru site-urile web blocate',
  'internet-page.settings-page.save-settings-button-label': 'Salvează setările',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Adaugă la listă:',
  'internet-page.lists.add-button-label': 'Adaugă',
  'internet-page.lists.remove-button-label': 'Șterge',
  'internet-page.lists.white-list.card-title': 'Lista sigură',
  'internet-page.lists.black-list.card-title': 'Lista blocată',
  'internet-page.lists.remove-prefix': 'Adresa introdusă începe cu "{prefix}", este recomandat să adăugați doar numele de domeniu. Doriți să eliminați "{prefix}" din adresă?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Pagini vizitate',

  'internet-page.searches.searches-pages-card-title': 'Fraze căutate',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Program',
  'internet-page.access-time.schedule-legend-label': 'Legendă program',
  'internet-page.access-time.schedule-internet-available-label': 'Internet disponibil',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet blocat',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Apeluri & Mesaje text (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Apeluri',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Mesaje text (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Ultima modificare:',
  'calls-text-messages-page.contacts-no-changes-label': 'Gol.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Contacte',

  // Localization Page
  'localization-page.headline-title': 'Locație',
  'localization-page.headline-title-off': 'Localizări, urmărirea GPS este oprită',
  'localization-page.map-hours-filter-label': 'Selectează intervalul de timp pentru a vizualiza istoricul locației',
  'localization-page.gps-tracking-on': 'Urmărirea GPS activată',
  'localization-page.gps-view-speed': 'Arată viteza de deplasare',
  'localization-page.map-loading-label': 'Se încarcă hărțile...',
  'localization-page.map-no-data': 'FĂRĂ DATE',

  'localization-page.one-gps-was-off-since': 'Serviciile de localizare ale dispozitivului au fost oprite din {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Serviciile de localizare ale dispozitivului au fost oprite pentru {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Serviciile de localizare ale dispozitivului au fost oprite din {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Serviciile de localizare ale dispozitivului au fost oprite pentru {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Baterie',
  'battery-page.chart-description': 'Nivelul bateriei',

  // Access Time Page
  'access-time-page.headline-title': 'Ora de acces',
  'access-time-page.nav-access-time-label-applications': 'Aplicații',
  'access-time-page.nav-access-time-label-internet': 'Pagini Web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Lista de video-uri',
  'youtube-page.no-videos': 'Niciun video în data selectată',
  'youtube-page.block-videoid': 'Blochează acest video',
  'youtube-page.block-channelid': 'Blocheaza acest canal',
  'youtube-page.video-blocked': 'Blocat',

  // Screen Pages
  'screenview-page.headline-title': 'Vizualizare ecran',
  'screenview-page.reload-button': 'Reîncarcă',
  'screenview-page.chart-description': 'Vizualizare ecran curentă',
  'screengrab-page.headline-title': 'Captură de ecran',
  'screengrab-page.chart-description': 'Captură de ecran',
  'screengrab-page.screen-save-time': 'Cât de des se realizează captura de ecran',
  'screengrab-page.screen-save-time-desc': 'Alege cât de des se realizează captura de ecran sau alege "dezactivat" pentru a nu face nicio captura de ecran',
  'screengrab-page.screen-save-time-disabled': 'dezactivat',
  'screengrab-page.no-screens-for-today': 'Niciuna captura de ecran realizata în această zi',
  'screengrab-page.no-screens-for-filter': 'Niciun ecran corespunzător criteriilor selectate',
  'screengrab-page.imgs-delete-description': 'Ești sigur că vrei să ștergi imaginile selectate?',
  'screengrab-page.imgs-delete-description-one': 'Ești sigur că vrei să ștergi imaginea selectată?',
  'screengrab-page.imgs-delete-label': 'Șterge',
  'screengrab-page.imgs-delete-select': 'Selecteaza pentru a șterge',
  'screengrab-page.imgs-delete-selected': 'Selectat:',
  'screengrab-page.imgs-delete-limit': 'Limita de capturi de ecran selectate a fost atinsă.',
  'screengrab-page.imgs-filter': 'Filtru:',
  'screengrab-page.imgs-filter-communicators': 'Comunicatori',
  'screengrab-page.imgs-filter-social': 'Rețele de socializare',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Alții',

  'screes-pages.device-offline': 'Dispozitivul este deconectat',
  'screes-pages.device-deactivated': 'Protecția a fost dezactivată temporar',
  'screes-pages.device-timeout': 'Dispozitivul nu a răspuns',
  'screes-pages.device-screenoff': 'Ecranul este oprit',
  'screes-pages.device-notallowed': 'Captura de ecran nu este permisă pe dispozitiv',
  'screes-pages.device-error': 'A apărut o eroare',

  'lazy-image.img-delete-description': 'Ești sigur că vrei să ștergi imaginea selectată?',

  // Applications Page
  'applications-page.headline-title': 'Aplicații',
  'applications-page.nav-access-time-label': 'Timp de acces',
  'applications-page.nav-statistics-label': 'Rezumat',
  'applications-page.nav-apps-list-label': 'Lista de aplicații',
  'applications-page.chart-activity-by-day-label': 'Activitate pe parcursul zilei',
  'applications-page.chart-hour-label': 'Oră',
  'applications-page.chart-activity-by-hour-minutes-label': 'Activitate pe parcursul zilei în minute',
  'applications-page.list-launched-count-label': 'Număr de lansări:',
  'applications-page.list-elapsed-time-label': 'Timp de funcționare:',
  'applications-page.introduction': 'Aplicații instalate',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Programează intervalul de timp',
  'applications-page.settings.schedule-legend-label': 'Legendă programare',
  'applications-page.settings.schedule-blocked-label': 'Aplicații blocate',
  'applications-page.settings.schedule-available-label': 'Aplicații disponibile',
  'applications-page.time-limit.description': 'Stabilește limita de timp pentru aplicații specifice. Alege câte ore pe zi este permisă utilizarea aplicației.',
  'applications-page.apps-headline-title': 'Aplicații disponibile',
  'applications-page.apps-first-seen-label': 'Prima dată văzută:',
  'applications-page.apps-available-switch-label': 'Disponibil',
  'applications-page.apps-not-available-switch-label': 'Indisponibil',
  'applications-page.apps-schedule-switch-label': 'Programat',
  'applications-page.apps-no-schedule-switch-label': 'Neprogramat',
  'applications-page.apps-time-limit-switch-label': 'Limită de timp',
  'applications-page.apps-no-time-limit-switch-label': 'Fără limită de timp',
  'applications-page.apps-show-uninstalled-label': 'Afișează aplicațiile dezinstalate',
  'applications-page.apps-show-apps-list': 'Alege aplicațiile cu limită de timp',

  'applications-page.used-count-zero-label': 'Nelansat',
  'applications-page.used-count-one-label': 'Lansat {value} dată, timp total: {totalTime}',
  'applications-page.used-count-many-label': 'Lansat {value} ori, timp total: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Timp total ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Linkul de activare a fost folosit sau este invalid',
  'email-confirmation-page.successful-confirm': 'Emailul a fost confirmat. Mulțumim!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Schimbă parola',
  'change-password-page.new-password-input-label': 'Parolă nouă',
  'change-password-page.new-password-repeated-input-label': 'Repetă parola nouă',
  'change-password-page.current-password-input-label': 'Parola curentă',
  'change-password-page.back-button-label': 'Înapoi',
  'change-password-page.change-button-label': 'SCHIMBĂ',
  'change-password-page.successful-change-label': 'Parola a fost schimbată corect',
  'change-password-page.wrong-current-password-label': 'Parola curentă greșită',
  'change-password-page.password-too-short-label': 'Parola nouă este prea scurtă',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENIU',
  'top-navigation.user-menu.account-label': 'Cont',
  'top-navigation.user-menu.sign-out': 'Ieși din cont',
  'top-navigation.user-menu.subscription-label': 'Plan de abonament',
  'top-navigation.user-menu.change-password-label': 'Schimbă parola',
  'top-navigation.user-menu.add-device-label': 'Adaugă dispozitiv',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplicații',
  'sidebar.menu-item.calls-and-text-messages': 'Apeluri & Mesaje(SMS)',
  'sidebar.menu-item.internet': 'Pagini web',
  'sidebar.menu-item.localizations': 'Localizări',
  'sidebar.menu-item.social-networks': 'Rețele sociale',
  'sidebar.menu-item.summary': 'Tablou de bord',
  'sidebar.menu-item.battery': 'Baterie',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Vizualizare ecran',
  'sidebar.menu-item.screengrab': 'Captură ecran',
  'sidebar.menu-item.access-time': 'Timp de acces',

  // Validator
  'phone-number.not-valid': 'Introduceți un număr de telefon valid',
  'input.required-label': 'Câmp obligatoriu',
  'input.select-required-label': 'Vă rugăm să selectați',
  'email.not-valid-label': 'Introduceți o adresă de e-mail validă',
  'domain-exist-label': 'Domeniul a fost adăugat',
  'domain-exist-other-label': 'Domeniul există pe o altă listă',
  'domain-not-valid': 'Domeniul nu este valid',
  'password-not-match-label': 'Parolele nu sunt identice',

  // misc
  'button-buy-label': 'Alege un plan',

  'transactiion-status-0': 'În așteptare',
  'transactiion-status-1': 'Finalizat',
  'transactiion-status--1': 'Anulat',
  'transactiion-status--2': 'Anulat – rambursare',
  'transactiion-status--3': 'În așteptarea confirmării',
  'transactiion-status--100': 'Început',
  'transactiion-status-other': 'Altul',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Succes',
  'reward-page.error-already-used': 'Acest cod a fost deja utilizat',
  'reward-page.error-invalid-code': 'Cod incorect',
  'reward-page.error-connection': 'Eroare de conexiune',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Revendică cuponul promoțional',
  'reward-page.get-now': 'Revendică',
  'reward-page.lic-was-extended-by-days': 'Planul tău de abonament a fost prelungit cu {rewardDays} zile!',
  'reward-page.there-was-an-error': 'Din păcate, a apărut o eroare.',
  'reward-page.lic-not-extended': 'Planul tău de abonament nu a fost prelungit.',
  'reward-page.button-close': 'Închide',

  // url item
  'url-item.copied': 'Copiat în clipboard',
  'url-item.copy-link': 'Copiază linkul',

  // APK howto

  'apk-howto-google-play': 'Google Play Store restricționează anumite permisiuni de aplicație care sunt necesare pentru a utiliza această funcție.',
  'apk-howto-on-the-phone': 'Pe telefonul copilului cu {applite} instalat',
  'apk-howto-step1': 'Dezinstalați {applite} dacă a fost descărcat anterior prin intermediul Google Play',
  'apk-howto-step2': 'Descărcați aplicația direct pe telefonul copilului prin intermediul site-ului nostru',
  'apk-howto-step2B': 'sau scanați codul QR de mai jos pe telefonul copilului',
  'apk-howto-step3': 'Instalați aplicația descărcată pe dispozitiv',
  'apk-howto-tutorial': 'Un ghid despre cum să instalați aplicația pe un telefon sau tabletă',

  // Cookies

  'cookies-info-uses': 'Acest site folosește cookie-uri.',
  'cookies-info-privacy-policy': 'Mai multe în politica de confidențialitate.',

  // Plan names

  'plan-name-demoExpired': 'Perioada de încercare a expirat',
  'plan-name-licExpired': 'Planul a expirat',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Perioadă de încercare',
  'plan-name-minimal': 'Bază',
  'plan-name-regular': 'Obișnuit',
  'plan-name-premium': 'Premium',

  // agreements

  'agreement-will-inform-user': 'Declar că îl voi informa pe utilizatorul ai cărui dispozitiv va fi instalată aplicația că activitatea și locația lor vor fi monitorizate.',

  'agreement-privacy-policy': 'Declar că am citit și acceptat <a href="{regulations}" target="_blank">termenii</a> și <a href="{privacy}" target="_blank">politica de confidențialitate</a>. Certific că am peste 18 ani și că voi adăuga în contul meu numai copii care sunt sub 18 ani și sunt dependenți mei legali.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Sunt de acord să primesc conținut de marketing de la {domain} cu privire la oferte promoționale la adresa de e-mail și numărul de telefon pe care le-am furnizat, precum și transmiterea detaliilor către partenerii de publicitate ai site-ului menționați în politica de confidențialitate în scopul publicității digitale. <a href="{marketing}" target="_blank">Mai mult</a>',

}

export default {
  locale: 'ro',
  name: 'Română',
  data: appLocalData,
  messages
} as LanguageLocale
