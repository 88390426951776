import React from 'react'
import I18nMessage from '../components/I18nMessages'
import { getBuildConfigStr, isWebView } from '../lib/utils'

type Props = {
    inline?: boolean
}

type ScreenSize = {
    width: number
    height: number
}

// Do not show RODO on WebView
let globalIsClicked = isWebView()

const MiniRodo: React.FC<Props> = ({
  inline
}) => {

    const [isClicked, setIsClicked] = React.useState(globalIsClicked)

    const [screenSize, setScreenSize] = React.useState<ScreenSize>( {width:window.innerWidth, height: window.innerHeight } )

    React.useEffect( () => {

        const handleResize = () => {
          setScreenSize( {width:window.innerWidth, height:window.innerHeight })
        }    
        
        window.addEventListener('resize',  handleResize )
    
      
        return () => {
          window.removeEventListener('resize', handleResize )
        }
    
      },[])
    

    function handleClicked()
    {
        globalIsClicked = true

        setIsClicked(globalIsClicked)
    }

    return (

        <React.Fragment>

            { !isClicked && (
                <div className={ inline && screenSize.height < 900 ?"rodo-inline" : "rodo-box" } >
                    <div>
                        <div>
                            <div className="rodo-x" onClick={handleClicked}>X</div>
                            <I18nMessage id="cookies-info-uses"/> <a href={getBuildConfigStr("PRIVACY_URL")} target="_blank" rel="noopener noreferrer"><I18nMessage id="cookies-info-privacy-policy"/></a>
                        </div>
                    </div>
                </div>
            )}
        
        </React.Fragment>
        
    )
}

export default MiniRodo
