import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/es'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': '¡Todo OK!',
  'side-effect.successful-message': 'Los datos se han guardado correctamente',
  'side-effect.internal-error-title': 'Algo salió mal',
  'side-effect.internal-error-message': 'Por favor, inténtelo de nuevo más tarde o compruebe su conexión a Internet',

  'monday-sshort-label': 'Lu',
  'tuesday-sshort-label': 'Ma',
  'wednesday-sshort-label': 'Mi',
  'thursday-sshort-label': 'Ju',
  'friday-sshort-label': 'Vi',
  'saturday-sshort-label': 'Sa',
  'sunday-sshort-label': 'Do',

  'monday-short-label': 'Lun',
  'tuesday-short-label': 'Mar',
  'wednesday-short-label': 'Mie',
  'thursday-short-label': 'Jue',
  'friday-short-label': 'Vie',
  'saturday-short-label': 'Sab',
  'sunday-short-label': 'Dom',

  'monday-label': 'Lunes',
  'tuesday-label': 'Martes',
  'wednesday-label': 'Miércoles',
  'thursday-label': 'Jueves',
  'friday-label': 'Viernes',
  'saturday-label': 'Sabado',
  'sunday-label': 'Domingo',

  'january-label': 'Enero',
  'february-label': 'Febrero',
  'march-label': 'Marzo',
  'april-label': 'Abril',
  'may-label': 'Mayo',
  'june-label': 'Junio',
  'july-label': 'Julio',
  'august-label': 'Agosto',
  'september-label': 'Septiembre',
  'october-label': 'Octubre',
  'november-label': 'Noviembre',
  'december-label': 'Diciembre',

  'interval-name-year': 'año',
  'interval-name-month': 'mes',
  'interval-name-week': 'semana',
  'interval-name-day': 'día',
  'interval-name-years': 'años',
  'interval-name-months': 'meses',
  'interval-name-weeks': 'semanas',
  'interval-name-days': 'días',

  'select-input.no-options-label': 'No hay opciones',
  'filter-input.label': 'Filtro:',

  'no-data.label': 'No hay datos para mostrar. Cambie los filtros o la fecha seleccionada.',
  'no-data.label-mid': 'No hay datos para mostrar. Cambie la fecha seleccionada.',
  'no-data.label-short': 'No hay datos para mostrar.',
  'empty-list-label': 'Lista vacía.',
  'overlay.empty-device-label': 'No se ha añadido ningun dispositivo a este perfil todavía.',
  'overlay.empty-profiles-label': 'No se ha añadido ningún perfil todavía.',
  'overlay.empty-device.add-device-label': 'Agregar dispositivo',

  'confirmation-modal.cancel-button': 'Cancelar',
  'confirmation-modal.confirm-button': 'CONFIRMAR',
  'confirmation-modal.button-yes': 'Sí',
  'confirmation-modal.button-no': 'No',

  // Date Picker
  'date-picker.select-date-label': 'Seleccionar fecha',

  // Date Filter
  'date-filter.select-label': 'Seleccionar rango',
  'date-filter.this-month': 'Este mes',
  'date-filter.this.week': 'Esta semana',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Seleccionar día',
  'date-filter.from-label': 'Desde: ',
  'date-filter.to-label': 'Hasta: ',

  // Time Picker
  'time-picker.unlimied-label': 'Ilimitado',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Agregar bono de tiempo solo por hoy',
  'time-picker.no-bonus': 'Sin bono',
  'time-picker.bonus': 'Bono',
  'time-picker.add-bonus-button-set': 'Establecer bono',
  'time-picker.add-bonus-button-cancel': 'Cancelar',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Rango de fechas',
  'profile-image-picker.girl-label': 'Niña',
  'profile-image-picker.boy-label': 'Niño',

  // Profile List
  'profile-list.remove-device-label': 'Eliminar dispositivo',
  'profile-list.move-device-label': 'Mover dispositivo a otro perfil',
  'profile-list.add-device-label': 'Agregar dispositivo',

  'profile-list.add-profile-label': 'Agregar perfil',
  'profile-list.edit-profile-label': 'Editar perfil',
  'profile-list.add-devices-label': 'Añadir nuevo dispositivo',
  'profile-list.remove-profile-label': 'Eliminar perfil',
  'profile-list.remove-profile-disabled-tooltip': 'Para eliminar su perfil, primero debe quitar todos los dispositivos asociados al perfil.',
  'profile-list.title': 'Lista de perfiles',

  // Profile Form
  'profile-form.full-name-label': 'Nombre',
  'profile-form.month-of-birth-label': 'Mes de nacimiento',
  'profile-form.year-of-birth-label': 'Año de nacimiento',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Cancelar',
  'profile-form-modal.create.submit-button': 'AÑADIR PERFIL',
  'profile-form-modal.create.title': 'Añadir perfil de niño',
  'profile-form-modal.edit.skip-button': 'Cancelar',
  'profile-form-modal.edit.submit-button': 'GUARDAR',
  'profile-form-modal.edit.title': 'Editar perfil de niño',
  'profile-form-modal.max-profiles-error': 'Límite de perfiles alcanzado',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Mover dispositivo a otro perfil',
  'profile-move-device-modal.cancel-button': 'Cancelar',
  'profile-move-device-modal.submit-button': 'Guardar',
  'profile-move-device-modal.select-profile-label': 'Elige perfil',

  // CallLog
  'call-log.duration-label': 'Duración de la llamada:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'AÑADIR PERFIL',
  'create-profile-page.skip-button': 'Cancelar',
  'create-profile-page.title': 'Agregar perfil de niño',
  'create-profile-page.description': 'Quién será supervisado',

  // Add device page
  'add-device-page.close-button': 'CERRAR',
  'add-device-page.description': 'Elige el tipo de dispositivo para instalar la aplicación',
  'add-device-page.title': 'Guía de instalación',
  'add-device-page.check': 'Consultar el manual',
  'add-device-page.mobile-title': 'Smartphone o tablet Android',
  'add-device-page.mobile-description': 'Versión de Android 6 o superior',
  'add-device-page.desktop-title': 'Escritorio o portátil Windows',
  'add-device-page.desktop-description': 'Versión de Windows 7 o superior',


  // Purchase Page
  'purchase-page.skip-label': 'Ir al panel de control',
  'purchase-page.title': 'Elige el mejor plan',
  'purchase-page.title.features': 'Todos los planes ofrecen las siguientes funciones',
  'purchase-page.title.reviews': 'Por qué nuestros usuarios nos aman',
  'purchase-page.subtitle': 'Si el programa no cumple con tus expectativas, te reembolsaremos el pago',
  'purchase-page.plan-highlight': 'Mejor valor',
  'purchase-page.monthly': 'mensual',
  'purchase-page.select': 'Escoger',
  'purchase-page.close': 'Cerrar',
  'purchase-page.money-back.title': 'Sin riesgo',
  'purchase-page.money-back.desc': 'Si no estás satisfecho, te devolveremos el dinero.',
  'purchase-page.header.check-1': 'La mejor aplicación de control parental',
  'purchase-page.header.check-2': 'Establecer límite de tiempo para juegos, YouTube y sitios web',
  'purchase-page.header.check-3': 'Cientos de miles de clientes satisfechos',
  'purchase-page.plan.year': 'año',
  'purchase-page.plan.optimal': 'Mejor valor',


  'purchase-page.modal.2y-header': '¡Oferta especial!',
  'purchase-page.modal.2y-sub-header': 'Elige el <b>plan de 2 años y ahorra</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Elige el <b>plan de 2 años y ahorra</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Protege hasta {devices} dispositivos',
  'purchase-page.modal.upgrade': 'Elige el descuento',
  'purchase-page.modal.no-thanks': 'Continúa con el plan de 1 año',


  'purchase-plan.features.title.feature_1': 'Límite de tiempo para aplicaciones y juegos',
  'purchase-plan.features.title.feature_2': 'Filtrado de sitios web',
  'purchase-plan.features.title.feature_3': 'Bloquear aplicaciones, juegos y sitios web',
  'purchase-plan.features.title.feature_4': 'Monitoreo de Youtube',
  'purchase-plan.features.title.feature_5': 'Visualización remota de la pantalla en vivo',
  'purchase-plan.features.title.feature_6': 'Acceso remoto',
  'purchase-plan.features.title.feature_7': 'Informes detallados',
  'purchase-plan.features.title.feature_8': 'Ubicación (solo teléfono)',
  'purchase-plan.features.title.feature_9': 'Revisar mensajes y llamadas',
  'purchase-plan.features.title.feature_10': 'Soporte técnico prioritario',

  'purchase-plan.features.desc.feature_1': 'Establece límites de tiempo para aplicaciones, juegos y acceso a internet. Además, programa las horas en las que se permite a tu hijo acceder a ellos',
  'purchase-plan.features.desc.feature_2': 'Todos los sitios web inapropiados están bloqueados. El programa utiliza un algoritmo que analiza el contenido del sitio web en busca de material inadecuado. También recopila información de una inmensa colección de bases de datos de sitios web y palabras inapropiadas, ambas se actualizan regularmente.',
  'purchase-plan.features.desc.feature_3': 'Bloquea las aplicaciones y sitios web que podrían tener un impacto negativo en tus hijos. Además, cualquier nuevo juego instalado requerirá tu autorización.',
  'purchase-plan.features.desc.feature_4': 'En el panel de control, monitorea todos los videos que tu hijo ve en YouTube. Bloquea cualquier video o bloquea completamente el acceso a los servicios de video.',
  'purchase-plan.features.desc.feature_5': 'Acceda de forma remota a una transmisión en vivo de la pantalla de su hijo y verifique su actividad durante el día. Esto permite mantener un ojo en el contenido al que accede su hijo.',
  'purchase-plan.features.desc.feature_6': 'Una vez que haya iniciado sesión, es posible acceder al panel de control de los padres desde cualquier lugar y desde cualquier dispositivo. En el tablero, ajuste la configuración, monitoree los dispositivos de los niños y establezca límites de tiempo.',
  'purchase-plan.features.desc.feature_7': 'En el panel de control parental, monitoree fácilmente la actividad de un niño en su computadora o teléfono, vea cuánto tiempo de pantalla han hecho, qué sitios web han visitado y mucho más.',
  'purchase-plan.features.desc.feature_8': 'Verifique la ubicación actual de su hijo y las ubicaciones pasadas en el mapa. Vea exactamente dónde estaba su hijo en cualquier día dado o en un momento determinado.',
  'purchase-plan.features.desc.feature_9': 'Puede revisar fácilmente los mensajes enviados y recibidos de su hijo, las llamadas telefónicas y los contactos guardados mediante el panel de padres.',
  'purchase-plan.features.desc.feature_10': 'Si necesita ayuda, siempre estamos disponibles. También hay muchas guías de video para ayudarlo a usar {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': '¡Muy eficiente! Protege completamente a mi hijo del contenido dañino de internet.',
  'purchase-plan.raitings.desc.raiting_2': '¡Justo lo que estaba buscando! Establezco un límite diario de una hora para que mis hijas accedan a internet y Avosmart se encarga de ello por mí.',
  'purchase-plan.raitings.desc.raiting_3': 'Recomiendo Avosmart porque tiene una variedad de funciones. Mis hijos están protegidos cuando usan internet y puedo controlar cuánto tiempo pasan jugando, etc.',
  'purchase-plan.raitings.desc.raiting_4': 'Era importante para mi esposa y yo poder vigilar las costumbres de nuestra hija en las redes sociales. Avosmart nos ayuda a mantener un equilibrio entre la vida digital y real de nuestra hija.',
  'purchase-plan.raitings.desc.raiting_5': 'Mi hijo solía pasar demasiado tiempo en internet, usando una laptop, tablet, etc. Con los informes proporcionados por Avosmart, sé exactamente qué páginas web visita mi hijo y durante cuánto tiempo.',
  'purchase-plan.raitings.desc.raiting_6': 'Ahora puedo chequear la ubicación actual de mi hijo en cualquier momento. ¡Un excelente servicio!',


  'account-settings-page.title': 'Configuración de la cuenta',
  'account-settings-page.automatic-update': 'Actualizaciones automáticas de la aplicación de Windows',
  'account-settings-page.timezone': 'Zona horaria: ',
  'account-settings-page.send-reports': 'Enviar informes diarios de actividad',


  'plans-info-page.title': 'Información de suscripción',
  'plans-info-page.plan': ' Plan:',
  'plans-info-page.max-devices': 'Máximo número de dispositivos:',
  'plans-info-page.valid-till': 'Fecha de caducidad:',

  'plans-info-page.your-plan': 'Tu plan:',
  'plans-info-page.subscription-information': 'Información de suscripción',
  'plans-info-page.subscription-created': 'Suscripción creada: ',
  'plans-info-page.subscription-current-period': 'Periodo de suscripción actual: ',
  'plans-info-page.subscription-renews-on': 'Tu plan se renueva el ',
  'plans-info-page.subscription-cancel-renewal': 'Cancelar renovación',
  'plans-info-page.subscription-not-renewed': 'Tu plan NO será renovado al final del período actual',
  'plans-info-page.subscription-enable-renewal': 'Habilitar renovación',
  'plans-info-page.subscription-price': 'Precio de la suscripción: ',
  'plans-info-page.subscription-price-amount': '{amount} cada {period}',


  'plans-info-page.upgrade-plan': 'Mejora tu plan',
  'plans-info-page.extend-lic': 'Extiende tu plan',
  'plans-info-page.subscriptions-portal': 'Administra tus métodos de pago',
  'plans-info-page.recent-payments': 'Ultimas transacciones',
  'plans-info-page.date-of-payment': 'Fecha de la transacción:',
  'plans-info-page.transaction-status': 'Estado de la transacción:',
  'plans-info-page.no-recent-payments': 'No hay últimas transacciones',
  'plans-info-page.btn-close': 'Cerrar',
  'plans-info-page.plan-devices': 'hasta {devices} dispositivos',
  'plans-info-page.plan-devices-types': 'computadoras/tabletas/teléfonos',


  'upgrade-page.title': 'Actualizar el plan',
  'upgrade-page.devices': 'Dispositivos: ',
  'upgrade-page.valid-till': 'Tu plan es válido hasta: ',
  'upgrade-page.confirm-upgrade': 'Tú plan será mejorado, serás cobrado {amount}',
  'upgrade-page.pay-now': 'Paga ahora {amount}',
  'upgrade-page.pay-then': 'Luego {amount} cada {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Compra una suscripción',
  'purchase-checkout.up-to-devices': 'Hasta {devices} dispositivos',
  'purchase-checkout.subscribtion-price': '{amount} cada {period}',
  'purchase-checkout.subscribe': 'Suscribirse',
  'purchase-checkout.cancel': 'Cancelar',


  // Purchase Status Page
  'purchase-status-page.title': 'Verificando pago',
  'purchase-status-page.status-pending': 'Pendiente',
  'purchase-status-page.status-completed': 'Completado',
  'purchase-status-page.status-canceled': 'Cancelado',
  'purchase-status-page.status-refund-finalized': 'Reembolso finalizado',
  'purchase-status-page.status-wating-for-conf': 'Esperando confirmación',
  'purchase-status-page.status-created': 'Creado',
  'purchase-status-page.status-unkown': 'Desconocido',
  'purchase-status-page.status-error': 'Error',
  'purchase-status-pag.back-button': 'Volver a la página de inicio',
  'purchase-status-pag.invoice-link': 'Quiero recibir una factura con IVA',

  // Login Page
  'login-page.brandline': 'Iniciar sesión',
  'login-page.brandline2': 'Filtrado de sitios web',
  'login-page.email-label': 'dirección de correo electrónico',
  'login-page.login-button': 'ENTRAR',
  'login-page.password-label': 'Contraseña',
  'login-page.recovery-link': '¿Olvidaste tu contraseña?',
  'login-page.invalid-login-error': 'La dirección de correo electrónico o la contraseña que ingresaste no es válida',

  // Register Page
  'register-page.create-account-label': '¿Necesitas una cuenta? Crea una nueva cuenta',
  'register-page.description': 'Crea tu cuenta gratuita',
  'register-page.email-label': 'Dirección de correo electrónico',
  'register-page.login-link-label': '¿Tiene una cuenta? Inicie sesión',
  'register-page.marketing-checkbox-label': 'Acepto recibir materiales de marketing y promocionales',
  'register-page.password-label': 'Contraseña',
  'register-page.phoneNumber-label': 'Número de teléfono',
  'register-page.terms-privacy-checkbox-label': 'Acepto la política de privacidad y términos de uso',
  'register-page.register-button': 'REGISTRAR',
  'register-page.register-success-title': 'Éxito',
  'register-page.register-success-message': 'Registro exitoso. Revise su correo para confirmar el email',
  'register-page.register-error-captcha': 'Error de captcha. Intente de nuevo.',
  'register-page.register-error-already-registered-label': 'Ya registrado. Intente iniciar sesión o recordar la contraseña.',
  'register-page.register-error-password-to-short-label': 'La contraseña es demasiado corta.',

  // Forgot Password Page
  'register-page.strong-pass': 'Su contraseña es demasiado simple y puede ser descifrada fácilmente por otras personas. Su contraseña debe contener letras mayúsculas y minúsculas, así como números, y debe tener al menos 8 caracteres de longitud. ¿Está seguro de que desea usar una contraseña simple?',
  'forgot-password-page.headline-title': 'Recordar contraseña',
  'forgot-password-page.email-label': 'Dirección de correo electrónico',
  'forgot-password-page.reset-button': 'RECORDAR CONTRASEÑA',
  'forgot-password-page.login-link-label': 'Volver a la página de inicio de sesión',
  'forgot-password-page.success-title': 'Restablecimiento de contraseña',
  'forgot-password-page.success-message': 'Revisa tu correo para seguir las instrucciones sobre cómo restablecer tu contraseña',
  'forgot-password-page.email-not-confirmed': 'Tu dirección de correo electrónico no está verificada. La restablecimiento de la contraseña no está disponible.',

  // Update Password Page
  'update-password-page.headline-title': 'Estableciendo una nueva contraseña',
  'update-password-page.warning-title': 'NOTA:',
  'update-password-page.warning-description': 'Establecer una nueva contraseña resultará en la pérdida de datos de ubicación previamente guardados. Los datos restantes no se perderán. ',
  'update-password-page.warning-agreement': 'Entiendo y acepto la pérdida de datos de ubicación.',
  'update-password-page.new-password': 'Nueva contraseña:',
  'update-password-page.new-password-retype': 'Repite la nueva contraseña:',
  'update-password-page.submit': 'Establecer nueva contraseña',
  'update-password-page.alert-weak-password': 'Tu contraseña es demasiado simple y podría ser adivinada fácilmente por personas no autorizadas. La contraseña debe contener letras mayúsculas, letras minúsculas, números y tener al menos 8 caracteres de longitud. ¿Quieres usar una contraseña simple de todos modos?',
  'update-password-page.alert-agreement': 'Se requiere la aceptación de la pérdida de datos.',
  'update-password-page.error-message': '¡Error! El establecimiento de una nueva contraseña ha fallado.',
  'update-password-page.success-message': 'Nueva contraseña establecida',
  'update-password-page.unknow-error-message': 'Algo salió mal',
  'update-password-page.error-link': 'Enlace incorrecto',
  'update-password-page.error-posswords-not-equal': 'Las contraseñas no son iguales',
  'update-password-page.error-posswords-length': 'Contraseñas muy cortas',
  'update-password-page.error-wrong-code': 'Código incorrecto',
  'update-password-page.register-success-title': '¡Éxito!',
  'update-password-page.register-success-message': 'Nueva contraseña establecida.',

  // Error Page
  'not-found-page.title': 'Vaya... parece que ha ocurrido un error.',
  'not-found-page.error-code-text': 'Código de error',
  'not-found-page.go-back-home-button': 'VOLVER A INICIO',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Tu plan finaliza el {date}. Por favor, considera la compra de una suscripción hoy',
  'panel-page.purchase-demo-alert': 'Actualmente estás usando la versión de prueba.',
  'panel-page.purchase-expired-alert': 'Actualmente estás usando una versión de prueba caducada. Compra suscripciones para continuar',
  'panel-page.purchase-lic-expired-alert': 'Tu plan de suscripción ha caducado. Compra suscripciones para continuar',
  'panel-page.email-alert': 'No has confirmado tu dirección de correo electrónico',
  'panel-page.button-email-resend': 'Reenviar el correo electrónico',
  'panel-page.email-alert-resend': ' o enviar el correo electrónico una vez más.',
  'panel-page.email-alert-resend-title': 'Confirmación',
  'panel-page.email-alert-resend-msg': '¡Correo electrónico ha sido enviado!',
  'panel-page.profile-delete-description': '¿Estás seguro de que quieres eliminar el perfil seleccionado?',
  'panel-page.profile-delete-device-description': '¿Estás seguro de que quieres eliminar el dispositivo seleccionado? Se perderán datos.',
  'panel-page.profile-delete-device-second-description': 'La protección en el dispositivo se desactivará. ¿Estás seguro de que quieres continuar?',

  'panel-page.email-confirmation-overlay-close': 'Cerrar',
  'panel-page.email-confirmation-overlay-postpone': 'Más tarde',
  'panel-page.email-confirmation-overlay-resend': 'Reenviar el correo electrónico',
  'panel-page.email-confirmation-overlay-header': 'Tu dirección de correo electrónico a continuación no ha sido confirmada',
  'panel-page.email-confirmation-overlay-desc': "Si has cometido un error con la dirección de correo electrónico anterior, por favor avísanos inmediatamente. <br>Si no recibiste el correo electrónico, por favor haz clic en 'reenviar' y revisa tus otras carpetas de correo electrónico (incluyendo junk/spam).",

  'panel-page.email-confirmation-code-success-header': 'Gracias',
  'panel-page.email-confirmation-code-success-desc': '¡Se ha confirmado el correo electrónico!',
  'panel-page.email-confirmation-code-header': 'Por favor, introduce el código de confirmación de 6 dígitos que <br> se envió a tu dirección de correo electrónico.',
  'panel-page.email-confirmation-code-error-invalid-code': 'El código proporcionado es inválido',
  'panel-page.email-confirmation-code-error': 'Se produjo un error inesperado al validar el código',

  // Summary Page
  'summary-page.title': 'Tablero',
  'summary-page.top-apps.title': 'Principales aplicaciones',
  'summary-page.top-blocked.title': 'Principales bloqueos',
  'summary-page.top-domains.title': 'Páginas web más visitadas',
  'summary-page.top-search.title': 'Principales búsquedas',
  'summary-page.access-disable': 'Desactivación temporal',
  'internet-page.access-time-limit-set-label': 'Límite diario para el acceso a internet:',
  'internet-page.access-time-limit-description': 'Establece límites de tiempo diarios para cada dispositivo y elige cuántas horas de uso de internet están permitidas.',
  'disable-access.protection-enabled': 'La protección está activa',
  'disable-access.protection-disabled-for': 'La protección está temporalmente desactivada, quedan {time} minutos',
  'disable-access.device-offline': 'Dispositivo no disponible',
  'disable-access.protection-status': 'Estado de protección',


  // Internet Page
  'internet-page.headline-title': 'Páginas web',
  'internet-page.access-time-page-title': 'Tiempo de acceso',
  'internet-page.settings-page-title': 'Ajustes',
  'internet-page.statistics-page-title': 'Estadísticas',
  'internet-page.searches-page-title': 'Búsquedas',

  'internet-page.statistics-page-www-ok': 'Sitio web aprobado',
  'internet-page.statistics-page-www-ok-white': 'Sitio web seguro',
  'internet-page.statistics-page-www-timelimit': 'Sitio web bloqueado debido a límites de tiempo',
  'internet-page.statistics-page-www-userdef': 'Sitio web bloqueado, definiciones de usuario',
  'internet-page.statistics-page-www-content': 'Sitio web bloqueado, contenido inapropiado',


  'internet-page.white-black-list-page-title': 'Sitios web seguros / bloqueados',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtros',
  'internet-page.settings-page.white-list-only-label': 'Solo permitir acceso a sitios en la lista de seguros',
  'internet-page.settings-page.block-erotic-label': 'Bloquear el acceso a sitios web eróticos',
  'internet-page.settings-page.block-offensive-label': 'Bloquear el acceso a sitios web ofensivos, agresivos',
  'internet-page.settings-page.block-file-sites-label': 'Bloquear el acceso a páginas que permiten descargar archivos de Internet',
  'internet-page.settings-page.block-video-label': 'Bloquear el acceso a portales de vídeo',
  'internet-page.settings-page.block-social-label': 'Bloquear acceso a redes sociales',
  'internet-page.settings-page.block-doc-label': 'Bloquear que se descarguen documentos',
  'internet-page.settings-page.block-exe-label': 'Bloquear que se descarguen programas',
  'internet-page.settings-page.excludeYT-label': 'No forzar la búsqueda segura en YouTube',
  'internet-page.settings-page.block-new-apps': 'Bloquear nuevas aplicaciones',
  'internet-page.settings-page.block-settings': 'Bloquear acceso a ajustes',
  'internet-page.settings-page.custom-msg.card-tile': 'Mensaje para páginas bloqueadas',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Usar mensaje personalizado para sitios web bloqueados',
  'internet-page.settings-page.save-settings-button-label': 'Guardar ajustes',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Agregar a la lista:',
  'internet-page.lists.add-button-label': 'Agregar',
  'internet-page.lists.remove-button-label': 'Eliminar',
  'internet-page.lists.white-list.card-title': 'Lista Segura',
  'internet-page.lists.black-list.card-title': 'Lista de Bloqueados',
  'internet-page.lists.remove-prefix': 'La dirección ingresada comienza con "{prefix}", se recomienda agregar solo el nombre de dominio. ¿Deseas quitar "{prefix}" de la dirección?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Páginas visitadas',

  'internet-page.searches.searches-pages-card-title': 'Frases buscadas',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Horario',
  'internet-page.access-time.schedule-legend-label': 'Leyenda del horario',
  'internet-page.access-time.schedule-internet-available-label': 'Internet disponible',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet bloqueado',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Llamadas y mensajes de texto (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Llamadas',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Mensajes de texto (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Último cambio:',
  'calls-text-messages-page.contacts-no-changes-label': 'Vacío.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Contactos',

  // Localization Page
  'localization-page.headline-title': 'Ubicación',
  'localization-page.headline-title-off': 'Localizaciones, el rastreo GPS está desactivado',
  'localization-page.map-hours-filter-label': 'Selecciona un rango de tiempo para ver el historial de ubicación',
  'localization-page.gps-tracking-on': 'Rastreo GPS habilitado',
  'localization-page.gps-view-speed': 'Mostrar velocidad de viaje',
  'localization-page.map-loading-label': 'Cargando mapas...',
  'localization-page.map-no-data': 'SIN DATOS',

  'localization-page.one-gps-was-off-since': 'Los servicios de ubicación del dispositivo se han desactivado desde {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Los servicios de ubicación del dispositivo se han desactivado durante {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Los servicios de ubicación del dispositivo se han desactivado desde {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Los servicios de ubicación del dispositivo se han desactivado durante {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Batería',
  'battery-page.chart-description': 'Nivel de batería',

  // Access Time Page
  'access-time-page.headline-title': 'Tiempo de acceso',
  'access-time-page.nav-access-time-label-applications': 'Aplicaciones',
  'access-time-page.nav-access-time-label-internet': 'Páginas web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Lista de videos',
  'youtube-page.no-videos': 'No hay videos en la fecha seleccionada',
  'youtube-page.block-videoid': 'Bloquear este video',
  'youtube-page.block-channelid': 'Bloquear este canal',
  'youtube-page.video-blocked': 'Bloqueado',

  // Screen Pages
  'screenview-page.headline-title': 'Vista de pantalla',
  'screenview-page.reload-button': 'Recargar',
  'screenview-page.chart-description': 'Vista de pantalla actual',
  'screengrab-page.headline-title': 'Captura de pantalla',
  'screengrab-page.chart-description': 'Captura de pantalla',
  'screengrab-page.screen-save-time': 'Cada cuánto se hace la captura de pantalla',
  'screengrab-page.screen-save-time-desc': 'Elige cada cuánto se hace la captura de pantalla o elige "deshabilitado" para no tener ninguna captura de pantalla',
  'screengrab-page.screen-save-time-disabled': 'deshabilitado',
  'screengrab-page.no-screens-for-today': 'No se tomaron capturas de pantalla en este día',
  'screengrab-page.no-screens-for-filter': 'No hay pantallas que coincidan con los criterios seleccionados',
  'screengrab-page.imgs-delete-description': '¿Estás seguro de que quieres eliminar las imágenes seleccionadas?',
  'screengrab-page.imgs-delete-description-one': '¿Estás seguro de que quieres eliminar la imagen seleccionada?',
  'screengrab-page.imgs-delete-label': 'Eliminar',
  'screengrab-page.imgs-delete-select': 'Seleccionar para eliminar',
  'screengrab-page.imgs-delete-selected': 'Seleccionado:',
  'screengrab-page.imgs-delete-limit': 'Se ha alcanzado el límite de capturas de pantalla seleccionadas.',
  'screengrab-page.imgs-filter': 'Filtro:',
  'screengrab-page.imgs-filter-communicators': 'Comunicadores',
  'screengrab-page.imgs-filter-social': 'Redes Sociales',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Otros',

  'screes-pages.device-offline': 'El dispositivo está desconectado',
  'screes-pages.device-deactivated': 'La protección ha sido temporalmente desactivada',
  'screes-pages.device-timeout': 'El dispositivo no respondió',
  'screes-pages.device-screenoff': 'La pantalla está apagada',
  'screes-pages.device-notallowed': 'Captura de pantalla no permitida en el dispositivo',
  'screes-pages.device-error': 'Ha ocurrido un error',

  'lazy-image.img-delete-description': '¿Estás seguro de que quieres eliminar la imagen seleccionada?',

  // Applications Page
  'applications-page.headline-title': 'Aplicaciones',
  'applications-page.nav-access-time-label': 'Tiempo de acceso',
  'applications-page.nav-statistics-label': 'Resumen',
  'applications-page.nav-apps-list-label': 'Lista de aplicaciones',
  'applications-page.chart-activity-by-day-label': 'Actividad durante el día',
  'applications-page.chart-hour-label': 'Hora',
  'applications-page.chart-activity-by-hour-minutes-label': 'Actividad durante el día en minutos',
  'applications-page.list-launched-count-label': 'Conteo de lanzamientos:',
  'applications-page.list-elapsed-time-label': 'Tiempo de ejecución:',
  'applications-page.introduction': 'Aplicaciones instaladas',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Intervalo de tiempo programado',
  'applications-page.settings.schedule-legend-label': 'Leyenda de programación',
  'applications-page.settings.schedule-blocked-label': 'Aplicaciones bloqueadas',
  'applications-page.settings.schedule-available-label': 'Aplicaciones disponibles',
  'applications-page.time-limit.description': 'Establecer límite de tiempo para aplicaciones específicas. Elige cuantas horas al día se permite el uso de la aplicación.',
  'applications-page.apps-headline-title': 'Aplicaciones disponibles',
  'applications-page.apps-first-seen-label': 'Primera vez vista:',
  'applications-page.apps-available-switch-label': 'Disponible',
  'applications-page.apps-not-available-switch-label': 'No disponible',
  'applications-page.apps-schedule-switch-label': 'Programado',
  'applications-page.apps-no-schedule-switch-label': 'No programado',
  'applications-page.apps-time-limit-switch-label': 'Límite de tiempo',
  'applications-page.apps-no-time-limit-switch-label': 'Sin límite de tiempo',
  'applications-page.apps-show-uninstalled-label': 'Mostrar aplicaciones desinstaladas',
  'applications-page.apps-show-apps-list': 'Elige aplicaciones con tiempo limitado',

  'applications-page.used-count-zero-label': 'No lanzado',
  'applications-page.used-count-one-label': 'Lanzado {value} vez, tiempo total: {totalTime}',
  'applications-page.used-count-many-label': 'Lanzado {value} veces, tiempo total: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Tiempo total ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'El enlace de activación ya se ha usado o es no válido',
  'email-confirmation-page.successful-confirm': 'Se ha confirmado el correo electrónico. ¡Gracias!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Cambiar contraseña',
  'change-password-page.new-password-input-label': 'Nueva contraseña',
  'change-password-page.new-password-repeated-input-label': 'Repetir nueva contraseña',
  'change-password-page.current-password-input-label': 'Contraseña actual',
  'change-password-page.back-button-label': 'Atrás',
  'change-password-page.change-button-label': 'CAMBIAR',
  'change-password-page.successful-change-label': 'La contraseña ha sido cambiada correctamente',
  'change-password-page.wrong-current-password-label': 'Contraseña actual incorrecta',
  'change-password-page.password-too-short-label': 'La nueva contraseña es demasiado corta',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENÚ',
  'top-navigation.user-menu.account-label': 'Cuenta',
  'top-navigation.user-menu.sign-out': 'Cerrar sesión',
  'top-navigation.user-menu.subscription-label': 'Plan de suscripción',
  'top-navigation.user-menu.change-password-label': 'Cambiar contraseña',
  'top-navigation.user-menu.add-device-label': 'Agregar dispositivo',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplicaciones',
  'sidebar.menu-item.calls-and-text-messages': 'Llamadas & Textos (SMS)',
  'sidebar.menu-item.internet': 'Páginas web',
  'sidebar.menu-item.localizations': 'Localizaciones',
  'sidebar.menu-item.social-networks': 'Redes sociales',
  'sidebar.menu-item.summary': 'Tablero',
  'sidebar.menu-item.battery': 'Batería',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Vista de pantalla',
  'sidebar.menu-item.screengrab': 'Captura de pantalla',
  'sidebar.menu-item.access-time': 'Tiempo de acceso',

  // Validator
  'phone-number.not-valid': 'Ingrese un número de teléfono válido',
  'input.required-label': 'Campo requerido',
  'input.select-required-label': 'Por favor selecciona',
  'email.not-valid-label': 'Ingresar una dirección de correo electrónico válida',
  'domain-exist-label': 'El dominio fue agregado',
  'domain-exist-other-label': 'El dominio existe en otra lista',
  'domain-not-valid': 'El dominio no es válido',
  'password-not-match-label': 'Las contraseñas no coinciden',

  // misc
  'button-buy-label': 'Elije un plan',

  'transactiion-status-0': 'En espera',
  'transactiion-status-1': 'Completado',
  'transactiion-status--1': 'Cancelado',
  'transactiion-status--2': 'Cancelado - reembolso',
  'transactiion-status--3': 'Esperando confirmación',
  'transactiion-status--100': 'Iniciado',
  'transactiion-status-other': 'Otro',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Éxito',
  'reward-page.error-already-used': 'Este código ya ha sido utilizado',
  'reward-page.error-invalid-code': 'Código incorrecto',
  'reward-page.error-connection': 'Error de conexión',
  'reward-page.additional-bonus': 'Bonificación',
  'reward-page.get-additional-bonus': 'Reclamar cupón promocional',
  'reward-page.get-now': 'Reclamar',
  'reward-page.lic-was-extended-by-days': '¡Tu plan de suscripción se ha extendido por {rewardDays} días!',
  'reward-page.there-was-an-error': 'Desafortunadamente, ha ocurrido un error.',
  'reward-page.lic-not-extended': 'Tu plan de suscripción no ha sido extendido.',
  'reward-page.button-close': 'Cerrar',

  // url item
  'url-item.copied': 'Copiado al portapapeles',
  'url-item.copy-link': 'Copiar enlace',

  // APK howto

  'apk-howto-google-play': 'Google Play Store restringe ciertos permisos de aplicación que son necesarios para utilizar esta función.',
  'apk-howto-on-the-phone': 'En el teléfono del niño con {applite} instalado',
  'apk-howto-step1': 'Desinstala {applite} si se descargó previamente a través de Google Play',
  'apk-howto-step2': 'Descarga la aplicación directamente en el teléfono del niño a través de nuestro sitio web',
  'apk-howto-step2B': 'o escanea el código QR a continuación en el teléfono del niño',
  'apk-howto-step3': 'Instala la aplicación descargada en el dispositivo',
  'apk-howto-tutorial': 'Una guía sobre cómo instalar la aplicación en un teléfono o tableta',

  // Cookies

  'cookies-info-uses': 'Este sitio web utiliza cookies.',
  'cookies-info-privacy-policy': 'Más en la política de privacidad.',

  // Plan names

  'plan-name-demoExpired': 'Prueba expirada',
  'plan-name-licExpired': 'Plan expirado',
  'plan-name-reward': 'Bono',
  'plan-name-demo': 'Prueba',
  'plan-name-minimal': 'Básico',
  'plan-name-regular': 'Regular',
  'plan-name-premium': 'Premium',

  // agreements

  'agreement-will-inform-user': 'Declaro que informaré al usuario en cuyo dispositivo se instalará la aplicación de que su actividad y ubicación serán monitoreadas.',

  'agreement-privacy-policy': 'Declaro que he leído y aceptado los <a href="{regulations}" target="_blank">términos</a> y la <a href="{privacy}" target="_blank">política de privacidad</a>. Certifico que soy mayor de 18 años y añadiré a mi cuenta solo a niños que sean menores de 18 años y sean mis dependientes legales.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Doy mi consentimiento para recibir contenido de marketing de {domain} sobre ofertas promocionales a la dirección de correo electrónico y número de teléfono que proporcioné, así como para reenviar los detalles a los socios publicitarios del sitio mencionados en la política de privacidad con el propósito de publicidad digital. <a href="{marketing}" target="_blank">Más</a>',

}

export default {
  locale: 'es',
  name: 'Español',
  data: appLocalData,
  messages
} as LanguageLocale
