import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/cs'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Vše v pořádku!',
  'side-effect.successful-message': 'Data byla správně uložena',
  'side-effect.internal-error-title': 'Něco se pokazilo',
  'side-effect.internal-error-message': 'Zkuste to prosím později nebo zkontrolujte své internetové připojení',

  'monday-sshort-label': 'Po',
  'tuesday-sshort-label': 'Út',
  'wednesday-sshort-label': 'St',
  'thursday-sshort-label': 'Čt',
  'friday-sshort-label': 'Pá',
  'saturday-sshort-label': 'So',
  'sunday-sshort-label': 'Ne',

  'monday-short-label': 'Pon',
  'tuesday-short-label': 'Úte',
  'wednesday-short-label': 'Stř',
  'thursday-short-label': 'Čtv',
  'friday-short-label': 'Pát',
  'saturday-short-label': 'Sob',
  'sunday-short-label': 'Ned',

  'monday-label': 'Pondělí',
  'tuesday-label': 'Úterý',
  'wednesday-label': 'Středa',
  'thursday-label': 'Čtvrtek',
  'friday-label': 'Pátek',
  'saturday-label': 'Sobota',
  'sunday-label': 'Neděle',

  'january-label': 'Leden',
  'february-label': 'Únor',
  'march-label': 'Březen',
  'april-label': 'Duben',
  'may-label': 'Květen',
  'june-label': 'Červen',
  'july-label': 'Červenec',
  'august-label': 'Srpen',
  'september-label': 'Září',
  'october-label': 'Říjen',
  'november-label': 'Listopad',
  'december-label': 'Prosinec',

  'interval-name-year': 'rok',
  'interval-name-month': 'měsíc',
  'interval-name-week': 'týden',
  'interval-name-day': 'den',
  'interval-name-years': 'roky',
  'interval-name-months': 'měsíce',
  'interval-name-weeks': 'týdny',
  'interval-name-days': 'dny',

  'select-input.no-options-label': 'Žádné možnosti',
  'filter-input.label': 'Filtr:',

  'no-data.label': 'Žádná data k zobrazení. Změňte filtry nebo vybrané datum.',
  'no-data.label-mid': 'Žádná data k zobrazení. Změňte vybrané datum.',
  'no-data.label-short': 'Žádná data k zobrazení.',
  'empty-list-label': 'Seznam je prázdný.',
  'overlay.empty-device-label': 'K tomuto profilu dosud nebyla přidána žádná zařízení.',
  'overlay.empty-profiles-label': 'Dosud nebyl přidán žádný profil.',
  'overlay.empty-device.add-device-label': 'Přidat zařízení',

  'confirmation-modal.cancel-button': 'Zrušit',
  'confirmation-modal.confirm-button': 'POTVRDIT',
  'confirmation-modal.button-yes': 'Ano',
  'confirmation-modal.button-no': 'Ne',

  // Date Picker
  'date-picker.select-date-label': 'Vybrat datum',

  // Date Filter
  'date-filter.select-label': 'Vybrat rozsah',
  'date-filter.this-month': 'Tento měsíc',
  'date-filter.this.week': 'Tento týden',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Vyberte den',
  'date-filter.from-label': 'Od: ',
  'date-filter.to-label': 'Do: ',

  // Time Picker
  'time-picker.unlimied-label': 'Neomezeně',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Přidejte časový bonus pouze pro dnešek',
  'time-picker.no-bonus': 'Žádný bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Nastavit bonus',
  'time-picker.add-bonus-button-cancel': 'Zrušit',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Rozsah dat',
  'profile-image-picker.girl-label': 'Dívka',
  'profile-image-picker.boy-label': 'Chlapec',

  // Profile List
  'profile-list.remove-device-label': 'Odebrat zařízení',
  'profile-list.move-device-label': 'Přesunout zařízení do jiného profilu',
  'profile-list.add-device-label': 'Přidat zařízení',

  'profile-list.add-profile-label': 'Přidat profil',
  'profile-list.edit-profile-label': 'Upravit profil',
  'profile-list.add-devices-label': 'Přidat nové zařízení',
  'profile-list.remove-profile-label': 'Odebrat profil',
  'profile-list.remove-profile-disabled-tooltip': 'Abyste mohli smazat svůj profil, musíte nejprve odebrat všechna zařízení připojená k profilu.',
  'profile-list.title': 'Seznam profilů',

  // Profile Form
  'profile-form.full-name-label': 'Jméno',
  'profile-form.month-of-birth-label': 'Měsíc narození',
  'profile-form.year-of-birth-label': 'Rok narození',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Zrušit',
  'profile-form-modal.create.submit-button': 'PŘIDAT PROFIL',
  'profile-form-modal.create.title': 'Přidat profil dítěte',
  'profile-form-modal.edit.skip-button': 'Zrušit',
  'profile-form-modal.edit.submit-button': 'ULOŽIT',
  'profile-form-modal.edit.title': 'Upravit profil dítěte',
  'profile-form-modal.max-profiles-error': 'Dosažen limit profilů',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Přesuňte zařízení do jiného profilu',
  'profile-move-device-modal.cancel-button': 'Zrušit',
  'profile-move-device-modal.submit-button': 'Uložit',
  'profile-move-device-modal.select-profile-label': 'Vyberte profil',

  // CallLog
  'call-log.duration-label': 'Délka hovoru:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'PŘIDAT PROFIL',
  'create-profile-page.skip-button': 'Zrušit',
  'create-profile-page.title': 'Přidat profil dítěte',
  'create-profile-page.description': 'Kdo bude sledován',

  // Add device page
  'add-device-page.close-button': 'ZAVŘÍT',
  'add-device-page.description': 'Vyberte typ zařízení pro instalaci aplikace',
  'add-device-page.title': 'Instalační průvodce',
  'add-device-page.check': 'Zkontrolujte manuál',
  'add-device-page.mobile-title': 'Android smartphone nebo tablet',
  'add-device-page.mobile-description': 'Verze Androidu 6 nebo vyšší',
  'add-device-page.desktop-title': 'Windows desktop nebo laptop',
  'add-device-page.desktop-description': 'Windows verze 7 nebo vyšší',


  // Purchase Page
  'purchase-page.skip-label': 'Jděte na palubovku',
  'purchase-page.title': 'Vyberte nejlepší plán',
  'purchase-page.title.features': 'Všechny plány nabízejí následující funkce',
  'purchase-page.title.reviews': 'Proč nás naši uživatelé milují',
  'purchase-page.subtitle': 'Pokud program nesplňuje vaše očekávání, vrátíme platbu',
  'purchase-page.plan-highlight': 'Nejlepší hodnota',
  'purchase-page.monthly': 'měsíčně',
  'purchase-page.select': 'Vyberte',
  'purchase-page.close': 'Zavřít',
  'purchase-page.money-back.title': 'Bez rizika',
  'purchase-page.money-back.desc': 'Pokud nejste spokojeni, peníze vám vrátíme.',
  'purchase-page.header.check-1': 'Nejlepší rodičovská kontrolní aplikace',
  'purchase-page.header.check-2': 'Nastavte časový limit pro hry, YouTube a webové stránky',
  'purchase-page.header.check-3': 'Stovky tisíc spokojených zákazníků',
  'purchase-page.plan.year': 'rok',
  'purchase-page.plan.optimal': 'Nejlepší hodnota',


  'purchase-page.modal.2y-header': 'Speciální nabídka!',
  'purchase-page.modal.2y-sub-header': 'Vyberte si <b>2letý plán a ušetřete</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Vyberte si <b>2letý plán a ušetřete</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Chraňte až {devices} zařízení',
  'purchase-page.modal.upgrade': 'Vyberte slevu',
  'purchase-page.modal.no-thanks': 'Pokračujte s plánem na 1 rok',


  'purchase-plan.features.title.feature_1': 'Časový limit pro aplikace a hry',
  'purchase-plan.features.title.feature_2': 'Filtrování webových stránek',
  'purchase-plan.features.title.feature_3': 'Blokování aplikací, her a webových stránek',
  'purchase-plan.features.title.feature_4': 'Monitorování Youtube',
  'purchase-plan.features.title.feature_5': 'Živé vzdálené sledování obrazovky',
  'purchase-plan.features.title.feature_6': 'Vzdálený přístup',
  'purchase-plan.features.title.feature_7': 'Podrobné zprávy',
  'purchase-plan.features.title.feature_8': 'Poloha (pouze u telefonu)',
  'purchase-plan.features.title.feature_9': 'Kontrola zpráv a hovorů',
  'purchase-plan.features.title.feature_10': 'Technická podpora s prioritou',

  'purchase-plan.features.desc.feature_1': 'Nastavte časové limity pro aplikace, hry a přístup na internet. Navíc naplánujte, v kterých hodinách může vaše dítě tyto služby využívat',
  'purchase-plan.features.desc.feature_2': 'Všechny nevhodné webové stránky jsou blokovány. Program používá algoritmus, který analyzuje obsah webových stránek pro jakýkoli nevhodný materiál. Také shromažďuje informace z obrovské databáze nevhodných webových stránek a slov, které jsou pravidelně aktualizovány.',
  'purchase-plan.features.desc.feature_3': 'Blokování aplikací a webových stránek, které by mohly mít negativní dopad na vaše děti. Navíc bude jakákoliv nově nainstalovaná hra vyžadovat vaše povolení.',
  'purchase-plan.features.desc.feature_4': 'Na palubní desce sledujte všechna videa, která vaše dítě sleduje na YouTube. Blokujte jakékoliv video nebo úplně blokujte přístup k video službám.',
  'purchase-plan.features.desc.feature_5': 'Vzdáleně přistupujte k živému vysílání obrazovky vašeho dítěte a kontrolujte jeho aktivitu během celého dne. To umožňuje pečlivě sledovat obsah, ke kterému se vaše dítě dostává.',
  'purchase-plan.features.desc.feature_6': 'Jakmile se přihlásíte, je možné přistupovat k rodičovskému panelu odkudkoli a z jakéhokoli zařízení. V panelu upravujte nastavení, sledujte zařízení dětí a nastavujte časové limity.',
  'purchase-plan.features.desc.feature_7': 'Na rodičovském panelu snadno sledujte aktivitu dítěte na jeho počítači nebo telefonu, zjistěte, kolik času strávili u obrazovky, které webové stránky navštívili a mnoho dalšího.',
  'purchase-plan.features.desc.feature_8': 'Zkontrolujte aktuální a minulé polohy vašeho dítěte na mapě. Přesně vidíte, kde bylo vaše dítě v určitý den nebo v určitý čas.',
  'purchase-plan.features.desc.feature_9': 'Snadno můžete zkontrolovat odeslané a přijaté zprávy vašeho dítěte, telefonáty a uložené kontakty pomocí rodičovského panelu.',
  'purchase-plan.features.desc.feature_10': 'Pokud budete potřebovat jakoukoli pomoc, jsme vám vždy k dispozici. K dispozici jsou také mnohé video návody, které vám usnadní práci s {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Velmi efektivní! Chrání mé dítě před škodlivým obsahem na internetu.',
  'purchase-plan.raitings.desc.raiting_2': 'Přesně to, co jsem hledal! Nastavil jsem svým dcerám denní limit jedné hodiny připojení k internetu a Avosmart se o to postará za mě.',
  'purchase-plan.raitings.desc.raiting_3': 'Doporučuji Avosmart, protože má různé funkce. Moje děti jsou chráněny při používání internetu a já mohu kontrolovat, kolik času tráví hraním her atd.',
  'purchase-plan.raitings.desc.raiting_4': 'Pro mého manžela a mě bylo důležité sledovat sociální média naší dcery. Avosmart nám pomáhá udržovat digitální a skutečný život naší dcery vyvážený.',
  'purchase-plan.raitings.desc.raiting_5': 'Můj syn trávil na internetu příliš mnoho času pomocí notebooku, tabletu atd.. S hlášeními od Avosmart vím přesně, které webové stránky můj syn navštěvuje a jak dlouho.',
  'purchase-plan.raitings.desc.raiting_6': 'Nyní mohu kdykoli zkontrolovat současnou polohu mého syna. Skvělá služba!',


  'account-settings-page.title': 'Nastavení účtu',
  'account-settings-page.automatic-update': 'Automatické aktualizace aplikace Windows',
  'account-settings-page.timezone': 'Časové pásmo :',
  'account-settings-page.send-reports': 'Posílat denní zprávy o aktivitě',


  'plans-info-page.title': 'Informace o předplatném',
  'plans-info-page.plan': ' Plán:',
  'plans-info-page.max-devices': 'Max. počet zařízení:',
  'plans-info-page.valid-till': 'Datum vypršení platnosti:',

  'plans-info-page.your-plan': 'Váš plán:',
  'plans-info-page.subscription-information': 'Informace o předplatném',
  'plans-info-page.subscription-created': 'Předplatné vytvořeno: ',
  'plans-info-page.subscription-current-period': 'Aktuální období předplatného: ',
  'plans-info-page.subscription-renews-on': 'Váš plán se obnoví ',
  'plans-info-page.subscription-cancel-renewal': 'Zrušit obnovení',
  'plans-info-page.subscription-not-renewed': 'Váš plán NEBUDE obnoven na konci současného období',
  'plans-info-page.subscription-enable-renewal': 'Povolit obnovení',
  'plans-info-page.subscription-price': 'Cena předplatného: ',
  'plans-info-page.subscription-price-amount': '{amount} každý {period}',


  'plans-info-page.upgrade-plan': 'Upgrade vašeho plánu',
  'plans-info-page.extend-lic': 'Prodloužit váš plán',
  'plans-info-page.subscriptions-portal': 'Spravovat vaše platební metody',
  'plans-info-page.recent-payments': 'Nejnovější transakce',
  'plans-info-page.date-of-payment': 'Datum transakce:',
  'plans-info-page.transaction-status': 'Stav transakce:',
  'plans-info-page.no-recent-payments': 'Žádné nejnovější transakce',
  'plans-info-page.btn-close': 'Zavřít',
  'plans-info-page.plan-devices': 'až pro {devices} zařízení',
  'plans-info-page.plan-devices-types': 'počítače/tablety/telefony',


  'upgrade-page.title': 'Upgrade plánu',
  'upgrade-page.devices': 'Zařízení: ',
  'upgrade-page.valid-till': 'Váš plán je platný do: ',
  'upgrade-page.confirm-upgrade': 'Váš plán bude upgradován, bude vám účtováno {amount}',
  'upgrade-page.pay-now': 'Zaplatit nyní {amount}',
  'upgrade-page.pay-then': 'Poté {amount} každých {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Koupit předplatné',
  'purchase-checkout.up-to-devices': 'Až pro {devices} zařízení',
  'purchase-checkout.subscribtion-price': '{amount} každý {period}',
  'purchase-checkout.subscribe': 'Předplatit',
  'purchase-checkout.cancel': 'Zrušit',


  // Purchase Status Page
  'purchase-status-page.title': 'Ověřování platby',
  'purchase-status-page.status-pending': 'Čeká na zpracování',
  'purchase-status-page.status-completed': 'Dokončeno',
  'purchase-status-page.status-canceled': 'Zrušeno',
  'purchase-status-page.status-refund-finalized': 'Vrácení peněz dokončeno',
  'purchase-status-page.status-wating-for-conf': 'Čeká na potvrzení',
  'purchase-status-page.status-created': 'Vytvořeno',
  'purchase-status-page.status-unkown': 'Neznámé',
  'purchase-status-page.status-error': 'Chyba',
  'purchase-status-pag.back-button': 'Zpět na domovskou stránku',
  'purchase-status-pag.invoice-link': 'Chci obdržet fakturu s DPH',

  // Login Page
  'login-page.brandline': 'Přihlásit se',
  'login-page.brandline2': 'Filtrování webových stránek',
  'login-page.email-label': 'e-mailová adresa',
  'login-page.login-button': 'PŘIHLÁSIT SE',
  'login-page.password-label': 'Heslo',
  'login-page.recovery-link': 'Zapomněli jste heslo?',
  'login-page.invalid-login-error': 'E-mailová adresa nebo heslo, které jste zadali, není platné',

  // Register Page
  'register-page.create-account-label': 'Potřebujete účet? Vytvořte nový účet',
  'register-page.description': 'Vytvořte si svůj bezplatný účet',
  'register-page.email-label': 'E-mailová adresa',
  'register-page.login-link-label': 'Máte účet? Přihlaste se',
  'register-page.marketing-checkbox-label': 'Souhlasím s přijímáním marketingových a propagačních materiálů',
  'register-page.password-label': 'Heslo',
  'register-page.phoneNumber-label': 'Telefonní číslo',
  'register-page.terms-privacy-checkbox-label': 'Souhlasím s politikou ochrany osobních údajů a podmínkami použití',
  'register-page.register-button': 'REGISTRACE',
  'register-page.register-success-title': 'Úspěch',
  'register-page.register-success-message': 'Registrace proběhla úspěšně. Zkontrolujte svou schránku pro potvrzení e-mailu',
  'register-page.register-error-captcha': 'Chyba captcha. Zkuste to znovu.',
  'register-page.register-error-already-registered-label': 'Již zaregistrován. Zkuste se přihlásit nebo si připomenout heslo.',
  'register-page.register-error-password-to-short-label': 'Heslo je příliš krátké.',

  // Forgot Password Page
  'register-page.strong-pass': 'Vaše heslo je příliš jednoduché a mohou ho snadno rozluštit ostatní lidé. Vaše heslo by mělo obsahovat velká a malá písmena, stejně jako čísla a mělo by být alespoň 8 znaků dlouhé. Jste si jisti, že chcete použít jednoduché heslo?',
  'forgot-password-page.headline-title': 'Připomenout heslo',
  'forgot-password-page.email-label': 'e-mailová adresa',
  'forgot-password-page.reset-button': 'PŘIPOMENOUT HESLO',
  'forgot-password-page.login-link-label': 'Zpět na přihlašovací stránku',
  'forgot-password-page.success-title': 'Obnovení hesla',
  'forgot-password-page.success-message': 'Zkontrolujte svou schránku pro instrukce, jak obnovit své heslo',
  'forgot-password-page.email-not-confirmed': 'Vaše e-mailová adresa není ověřena. Obnovení hesla není dostupné.',

  // Update Password Page
  'update-password-page.headline-title': 'Nastavení nového hesla',
  'update-password-page.warning-title': 'POZNÁMKA:',
  'update-password-page.warning-description': 'Nastavením nového hesla dojde ke ztrátě dříve uložených polohových údajů. Zbývající údaje nebudou ztraceny. ',
  'update-password-page.warning-agreement': 'Rozumím a akceptuji ztrátu polohových údajů.',
  'update-password-page.new-password': 'Nové heslo:',
  'update-password-page.new-password-retype': 'Opakujte nové heslo:',
  'update-password-page.submit': 'Nastavit nové heslo',
  'update-password-page.alert-weak-password': 'Vaše heslo je příliš jednoduché a mohl by ho snadno odhadnout neoprávněné osoby. Heslo by mělo obsahovat velká a malá písmena, čísla a mělo by být dlouhé alespoň 8 znaků. Chcete přesto použít jednoduché heslo?',
  'update-password-page.alert-agreement': 'Je vyžadován souhlas s přijetím ztráty dat.',
  'update-password-page.error-message': 'Chyba! Nastavení nového hesla se nezdařilo.',
  'update-password-page.success-message': 'Nové heslo nastaveno',
  'update-password-page.unknow-error-message': 'Něco se pokazilo',
  'update-password-page.error-link': 'Špatný odkaz',
  'update-password-page.error-posswords-not-equal': 'Hesla nejsou stejná',
  'update-password-page.error-posswords-length': 'Hesla jsou příliš krátká',
  'update-password-page.error-wrong-code': 'Špatný kód',
  'update-password-page.register-success-title': 'Úspěch!',
  'update-password-page.register-success-message': 'Nové heslo bylo nastaveno.',

  // Error Page
  'not-found-page.title': 'Jejda... zdá se, že došlo k chybě!',
  'not-found-page.error-code-text': 'Chybový kód',
  'not-found-page.go-back-home-button': 'VRÁTIT SE DOMŮ',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Váš plán končí {date}. Zvažte prosím nákup předplatného dnes',
  'panel-page.purchase-demo-alert': 'Aktuálně používáte zkušební verzi.',
  'panel-page.purchase-expired-alert': 'Aktuálně používáte vypršelou zkušební verzi. K pokračování zakupte předplatné',
  'panel-page.purchase-lic-expired-alert': 'Vaše předplatné vypršelo. K pokračování zakupte předplatné',
  'panel-page.email-alert': 'Nepotvrdili jste svou e-mailovou adresu',
  'panel-page.button-email-resend': 'Znovu poslat e-mail',
  'panel-page.email-alert-resend': ' nebo e-mail znovu poslat.',
  'panel-page.email-alert-resend-title': 'Potvrzení',
  'panel-page.email-alert-resend-msg': 'E-mail byl odeslán!',
  'panel-page.profile-delete-description': 'Jste si jisti, že chcete smazat vybraný profil?',
  'panel-page.profile-delete-device-description': 'Jste si jisti, že chcete smazat vybrané zařízení? Data budou ztracena.',
  'panel-page.profile-delete-device-second-description': 'Ochrana na zařízení bude zakázána. Opravdu chcete pokračovat?',

  'panel-page.email-confirmation-overlay-close': 'Zavřít',
  'panel-page.email-confirmation-overlay-postpone': 'Později',
  'panel-page.email-confirmation-overlay-resend': 'Znovu odeslat e-mail',
  'panel-page.email-confirmation-overlay-header': 'Vaše níže uvedená e-mailová adresa nebyla potvrzena',
  'panel-page.email-confirmation-overlay-desc': "Pokud jste udělali chybu s výše uvedenou e-mailovou adresou, dejte nám to, prosím, ihned vědět. <br>Pokud jste e-mail nedostali, klikněte prosím na \'znovu odeslat\' a zkontrolujte své ostatní e-mailové složky (včetně nevyžádané pošty/spamu).",

  'panel-page.email-confirmation-code-success-header': 'Děkuji',
  'panel-page.email-confirmation-code-success-desc': 'E-mail byl potvrzen!',
  'panel-page.email-confirmation-code-header': 'Zadejte prosím šestimístný potvrzovací kód, který <br> byl odeslán na vaši e-mailovou adresu.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Zadaný kód je neplatný',
  'panel-page.email-confirmation-code-error': 'Při ověřování kódu došlo k neočekávané chybě',

  // Summary Page
  'summary-page.title': 'Nástěnka',
  'summary-page.top-apps.title': 'Top aplikace',
  'summary-page.top-blocked.title': 'Nejvíce blokované',
  'summary-page.top-domains.title': 'Nejvíce navštěvované webové stránky',
  'summary-page.top-search.title': 'Top vyhledávání',
  'summary-page.access-disable': 'Dočasné deaktivace',
  'internet-page.access-time-limit-set-label': 'Denní limit pro přístup k internetu:',
  'internet-page.access-time-limit-description': 'Nastavte denní časové limity pro každé zařízení a určete, kolik hodin je povolené použití internetu.',
  'disable-access.protection-enabled': 'Ochrana je aktivní',
  'disable-access.protection-disabled-for': 'Ochrana je dočasně zakázána, zbývá {time} minut',
  'disable-access.device-offline': 'Zařízení není dostupné',
  'disable-access.protection-status': 'Stav ochrany',


  // Internet Page
  'internet-page.headline-title': 'Webové stránky',
  'internet-page.access-time-page-title': 'Čas přístupu',
  'internet-page.settings-page-title': 'Nastavení',
  'internet-page.statistics-page-title': 'Statistiky',
  'internet-page.searches-page-title': 'Vyhledávání',

  'internet-page.statistics-page-www-ok': 'Schválená webová stránka',
  'internet-page.statistics-page-www-ok-white': 'Bezpečná webová stránka',
  'internet-page.statistics-page-www-timelimit': 'Webová stránka blokována z důvodu časových omezení',
  'internet-page.statistics-page-www-userdef': 'Webová stránka blokována, definice uživatele',
  'internet-page.statistics-page-www-content': 'Webová stránka blokována, nevhodný obsah',


  'internet-page.white-black-list-page-title': 'Bezpečné / zablokované webové stránky',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtry',
  'internet-page.settings-page.white-list-only-label': 'Povolit přístup pouze k webům na seznamu bezpečných',
  'internet-page.settings-page.block-erotic-label': 'Blokovat přístup na erotické weby',
  'internet-page.settings-page.block-offensive-label': 'Blokovat přístup na urážlivé, agresivní webové stránky',
  'internet-page.settings-page.block-file-sites-label': 'Blokovat přístup na stránky, které umožňují stahování souborů z internetu',
  'internet-page.settings-page.block-video-label': 'Blokovat přístup na video portály',
  'internet-page.settings-page.block-social-label': 'Blokovat přístup na sociální sítě',
  'internet-page.settings-page.block-doc-label': 'Blokujte stahování dokumentů',
  'internet-page.settings-page.block-exe-label': 'Blokujte stahování programů',
  'internet-page.settings-page.excludeYT-label': 'Nevynucovat bezpečné hledání na YouTube',
  'internet-page.settings-page.block-new-apps': 'Blokovat nové aplikace',
  'internet-page.settings-page.block-settings': 'Blokovat přístup k nastavením',
  'internet-page.settings-page.custom-msg.card-tile': 'Zpráva pro blokované stránky',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Použít personalizovanou zprávu pro blokované weby',
  'internet-page.settings-page.save-settings-button-label': 'Uložit nastavení',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Přidat na seznam:',
  'internet-page.lists.add-button-label': 'Přidat',
  'internet-page.lists.remove-button-label': 'Odstranit',
  'internet-page.lists.white-list.card-title': 'Seznam bezpečných',
  'internet-page.lists.black-list.card-title': 'Blokován seznam',
  'internet-page.lists.remove-prefix': 'Zadaná adresa začíná na "{prefix}", doporučuje se přidat pouze název domény. Chcete odstranit "{prefix}" z adresy?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Navštívené stránky',

  'internet-page.searches.searches-pages-card-title': 'Vyhledávané fráze',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Plán',
  'internet-page.access-time.schedule-legend-label': 'Legenda plánu',
  'internet-page.access-time.schedule-internet-available-label': 'Internet je k dispozici',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet je blokován',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Hovory & Textové zprávy (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Hovory',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Textové zprávy (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Poslední změna:',
  'calls-text-messages-page.contacts-no-changes-label': 'Prázdné.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Kontakty',

  // Localization Page
  'localization-page.headline-title': 'Poloha',
  'localization-page.headline-title-off': 'Lokalizace, sledování GPS je vypnuto',
  'localization-page.map-hours-filter-label': 'Vyberte časový rozsah pro zobrazení historie polohy',
  'localization-page.gps-tracking-on': 'Sledování GPS je zapnuto',
  'localization-page.gps-view-speed': 'Zobrazit rychlost cesty',
  'localization-page.map-loading-label': 'Načítání map...',
  'localization-page.map-no-data': 'ŽÁDNÁ DATA',

  'localization-page.one-gps-was-off-since': 'Služby pro lokalizaci zařízení jsou vypnuty od {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Služby pro lokalizaci zařízení jsou vypnuty {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Služby pro lokalizaci zařízení jsou vypnuty od {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Služby pro lokalizaci zařízení jsou vypnuty {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Baterie',
  'battery-page.chart-description': 'Stav baterie',

  // Access Time Page
  'access-time-page.headline-title': 'Čas přístupu',
  'access-time-page.nav-access-time-label-applications': 'Aplikace',
  'access-time-page.nav-access-time-label-internet': 'Webové stránky',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Seznam videí',
  'youtube-page.no-videos': 'Žádná videa v daném datu',
  'youtube-page.block-videoid': 'Blokovat toto video',
  'youtube-page.block-channelid': 'Blokovat tento kanál',
  'youtube-page.video-blocked': 'Zablokováno',

  // Screen Pages
  'screenview-page.headline-title': 'Pohled na obrazovku',
  'screenview-page.reload-button': 'Znovu načíst',
  'screenview-page.chart-description': 'Aktuální pohled na obrazovku',
  'screengrab-page.headline-title': 'Snímek obrazovky',
  'screengrab-page.chart-description': 'Snímek obrazovky',
  'screengrab-page.screen-save-time': 'Jak často se snímek obrazovky pořizuje',
  'screengrab-page.screen-save-time-desc': 'Vyberte, jak často se má snímek obrazovky pořizovat, nebo zvolte "zakázáno" pro nevytváření jakýchkoliv snímků obrazovky',
  'screengrab-page.screen-save-time-disabled': 'zakázáno',
  'screengrab-page.no-screens-for-today': 'Tento den nebyly pořízeny žádné snímky obrazovky',
  'screengrab-page.no-screens-for-filter': 'Žádné obrazovky neodpovídají zadaným kritériím',
  'screengrab-page.imgs-delete-description': 'Opravdu chcete odstranit vybrané obrázky?',
  'screengrab-page.imgs-delete-description-one': 'Opravdu chcete smazat vybraný obrázek?',
  'screengrab-page.imgs-delete-label': 'Smazat',
  'screengrab-page.imgs-delete-select': 'Vybrat ke smazání',
  'screengrab-page.imgs-delete-selected': 'Vybráno:',
  'screengrab-page.imgs-delete-limit': 'Byl dosažen limit pro vybrané snímky obrazovky.',
  'screengrab-page.imgs-filter': 'Filtr:',
  'screengrab-page.imgs-filter-communicators': 'Komunikátory',
  'screengrab-page.imgs-filter-social': 'Sociální média',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Jiné',

  'screes-pages.device-offline': ' Zařízení je offline',
  'screes-pages.device-deactivated': 'Ochrana byla dočasně deaktivována',
  'screes-pages.device-timeout': 'Zařízení neodpovědělo',
  'screes-pages.device-screenoff': 'Obrazovka je vypnuta',
  'screes-pages.device-notallowed': 'Zachycení obrazovky není na tomto zařízení povoleno',
  'screes-pages.device-error': 'Došlo k chybě',

  'lazy-image.img-delete-description': 'Opravdu chcete smazat vybraný obrázek?',

  // Applications Page
  'applications-page.headline-title': 'Aplikace',
  'applications-page.nav-access-time-label': 'Čas přístupu',
  'applications-page.nav-statistics-label': 'Shrnutí',
  'applications-page.nav-apps-list-label': 'Seznam aplikací',
  'applications-page.chart-activity-by-day-label': 'Aktivita během dne',
  'applications-page.chart-hour-label': 'Hodina',
  'applications-page.chart-activity-by-hour-minutes-label': 'Aktivita během dne v minutách',
  'applications-page.list-launched-count-label': 'Počet spuštění:',
  'applications-page.list-elapsed-time-label': 'Doba provozu:',
  'applications-page.introduction': 'Instalované aplikace',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Naplánovaný časový rozsah',
  'applications-page.settings.schedule-legend-label': 'Legenda plánování',
  'applications-page.settings.schedule-blocked-label': 'Blokované aplikace',
  'applications-page.settings.schedule-available-label': 'Dostupné aplikace',
  'applications-page.time-limit.description': 'Nastavte časový limit pro konkrétní aplikace. Vyberte, kolik hodin denně je povolené jejich použití.',
  'applications-page.apps-headline-title': 'Dostupné aplikace',
  'applications-page.apps-first-seen-label': 'Poprvé viděn:',
  'applications-page.apps-available-switch-label': 'Dostupný',
  'applications-page.apps-not-available-switch-label': 'Nedostupný',
  'applications-page.apps-schedule-switch-label': 'Naplánováno',
  'applications-page.apps-no-schedule-switch-label': 'Nenaplánováno',
  'applications-page.apps-time-limit-switch-label': 'Časový limit',
  'applications-page.apps-no-time-limit-switch-label': 'Bez časového limitu',
  'applications-page.apps-show-uninstalled-label': 'Zobrazit odinstalované aplikace',
  'applications-page.apps-show-apps-list': 'Vyberte aplikace s časovým limitem',

  'applications-page.used-count-zero-label': 'Nespustil se',
  'applications-page.used-count-one-label': 'Spuštěno {value} krát, celkový čas: {totalTime}',
  'applications-page.used-count-many-label': 'Spuštěno {value} krát, celkový čas: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Celkový čas ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Aktivační odkaz byl použit nebo je neplatný',
  'email-confirmation-page.successful-confirm': 'Email byl potvrzen. Děkujeme!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Změnit heslo',
  'change-password-page.new-password-input-label': 'Nové heslo',
  'change-password-page.new-password-repeated-input-label': 'Zopakujte nové heslo',
  'change-password-page.current-password-input-label': 'Současné heslo',
  'change-password-page.back-button-label': 'Zpět',
  'change-password-page.change-button-label': 'ZMĚNIT',
  'change-password-page.successful-change-label': 'Heslo bylo úspěšně změněno',
  'change-password-page.wrong-current-password-label': 'Nesprávné aktuální heslo',
  'change-password-page.password-too-short-label': 'Nové heslo je příliš krátké',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Účet',
  'top-navigation.user-menu.sign-out': 'Odhlásit se',
  'top-navigation.user-menu.subscription-label': 'Předplatné',
  'top-navigation.user-menu.change-password-label': 'Změnit heslo',
  'top-navigation.user-menu.add-device-label': 'Přidat zařízení',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplikace',
  'sidebar.menu-item.calls-and-text-messages': 'Hovory & Texty(SMS)',
  'sidebar.menu-item.internet': 'Webové stránky',
  'sidebar.menu-item.localizations': 'Lokalizace',
  'sidebar.menu-item.social-networks': 'Sociální sítě',
  'sidebar.menu-item.summary': 'Přehled',
  'sidebar.menu-item.battery': 'Baterie',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Zobrazení obrazovky',
  'sidebar.menu-item.screengrab': 'Snímek obrazovky',
  'sidebar.menu-item.access-time': 'Čas přístupu',

  // Validator
  'phone-number.not-valid': 'Zadejte platné telefonní číslo',
  'input.required-label': 'Pole je povinné',
  'input.select-required-label': 'Prosím vyberte',
  'email.not-valid-label': 'Zadejte platnou e-mailovou adresu',
  'domain-exist-label': 'Doména byla přidána',
  'domain-exist-other-label': 'Doména existuje na jiném seznamu',
  'domain-not-valid': 'Doména není platná',
  'password-not-match-label': 'Hesla se neshodují',

  // misc
  'button-buy-label': 'Vyberte plán',

  'transactiion-status-0': 'Čeká se',
  'transactiion-status-1': 'Dokončeno',
  'transactiion-status--1': 'Zrušeno',
  'transactiion-status--2': 'Zrušeno – vrácení peněz',
  'transactiion-status--3': 'Čeká na potvrzení',
  'transactiion-status--100': 'Zahájeno',
  'transactiion-status-other': 'Jiné',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Úspěch',
  'reward-page.error-already-used': 'Tento kód byl již použit',
  'reward-page.error-invalid-code': 'Nesprávný kód',
  'reward-page.error-connection': 'Chyba připojení',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Nárok na propagační kupón',
  'reward-page.get-now': 'Uplatnit',
  'reward-page.lic-was-extended-by-days': 'Váš předplatný plán byl prodloužen o {rewardDays} dní!',
  'reward-page.there-was-an-error': 'Bohužel, došlo k chybě.',
  'reward-page.lic-not-extended': 'Váš předplatný plán nebyl prodloužen.',
  'reward-page.button-close': 'Zavřít',

  // url item
  'url-item.copied': 'Zkopírováno do schránky',
  'url-item.copy-link': 'Kopírovat odkaz',

  // APK howto

  'apk-howto-google-play': 'Google Play Store omezuje určitá povolení aplikace, která jsou potřebná k využití této funkce.',
  'apk-howto-on-the-phone': 'Na telefonu dítěte s instalovaným {applite}',
  'apk-howto-step1': 'Odinstalujte {applite}, pokud byla dříve stažena z Google Play',
  'apk-howto-step2': 'Stáhněte si aplikaci přímo na dětský telefon z našeho webu',
  'apk-howto-step2B': 'nebo naskenujte QR kód níže na dětském telefonu',
  'apk-howto-step3': 'Nainstalujte staženou aplikaci na zařízení',
  'apk-howto-tutorial': 'Průvodce, jak nainstalovat aplikaci na telefon nebo tablet',

  // Cookies

  'cookies-info-uses': 'Tato webová stránka používá cookies.',
  'cookies-info-privacy-policy': 'Více v zásadách ochrany osobních údajů.',

  // Plan names

  'plan-name-demoExpired': 'Zkušební doba skončila',
  'plan-name-licExpired': 'Plán vypršel',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Zkušební',
  'plan-name-minimal': 'Základní',
  'plan-name-regular': 'Pravidelný',
  'plan-name-premium': 'Premium',

  // agreements

  'agreement-will-inform-user': 'Prohlašuji, že uživatele, na jehož zařízení bude aplikace nainstalována, informuji, že jeho aktivita a poloha budou sledovány.',

  'agreement-privacy-policy': 'Prohlašuji, že jsem přečetl a přijal <a href="{regulations}" target="_blank">podmínky</a> a <a href="{privacy}" target="_blank">zásady ochrany osobních údajů</a>. Potvrzuji, že mi je více než 18 let a na svůj účet přidám pouze děti, které jsou mladší 18 let a jsou moji zákonní závislí.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Souhlasím s přijetím marketingového obsahu od {domain} týkajícím se propagačních akcí na e-mailovou adresu a telefonní číslo, které jsem poskytl, stejně jako s přeposíláním podrobností reklamním partnerům stránky uvedené v zásadách ochrany osobních údajů za účelem digitální reklamy. <a href="{marketing}" target="_blank">Více</a>',

}

export default {
  locale: 'cs',
  name: 'Čeština',
  data: appLocalData,
  messages
} as LanguageLocale
