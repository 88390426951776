import { addLocaleData } from 'react-intl'
import { registerLocale as registerDatePickerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import cs from 'date-fns/locale/cs'
import ro from 'date-fns/locale/ro'
import vi from 'date-fns/locale/vi'

// import vi from 'react-intl/locale-data/vi';

import { LanguageLocale } from './types/LanguageLocale'

import polishLocale from './locales/pl_PL'
import englishLocale from './locales/en_US'
import germanLocale from './locales/de_DE'
import spanishLocale from './locales/es_ES'
import italianLocale from './locales/it_IT'
import czechLocale from './locales/cz_CZ'
import romanianLocale from './locales/ro_RO'
import vietnamizeLocale from './locales/vn_VN'


export * from './types/LanguageLocale'

export interface Locales {
  pl: LanguageLocale,
  en: LanguageLocale
  de: LanguageLocale
  es: LanguageLocale
  it: LanguageLocale
  cz: LanguageLocale
  ro: LanguageLocale
  vn: LanguageLocale
}

registerDatePickerLocale('pl', pl)
registerDatePickerLocale('pl-PL', pl)
registerDatePickerLocale('en', en)
registerDatePickerLocale('en-US', en)
registerDatePickerLocale('de', de)
registerDatePickerLocale('de-DE', de)
registerDatePickerLocale('es', es)
registerDatePickerLocale('es-ES', es)
registerDatePickerLocale('it', it)
registerDatePickerLocale('it-IT', it)
registerDatePickerLocale('cz', cs)
registerDatePickerLocale('cz-CZ', cs)
registerDatePickerLocale('ro', ro)
registerDatePickerLocale('ro-RO', ro)
registerDatePickerLocale('vn', vi)
registerDatePickerLocale('vn-VN', vi)

addLocaleData([
  ...polishLocale.data,
  ...englishLocale.data,
  ...germanLocale.data,
  ...spanishLocale.data,
  ...italianLocale.data,
  ...czechLocale.data,
  ...romanianLocale.data,
  ...vietnamizeLocale.data
])

const languages: Locales = {
  pl: polishLocale,
  en: englishLocale,
  de: germanLocale,
  es: spanishLocale,
  it: italianLocale,
  cz: czechLocale,
  ro: romanianLocale,
  vn: vietnamizeLocale
}

export default languages
