import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/de'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Alles in Ordnung!',
  'side-effect.successful-message': 'Die Daten wurden korrekt gespeichert',
  'side-effect.internal-error-title': 'Etwas ist schief gelaufen',
  'side-effect.internal-error-message': 'Bitte versuchen Sie es später erneut oder überprüfen Sie Ihre Internetverbindung',

  'monday-sshort-label': 'Mo',
  'tuesday-sshort-label': 'Di',
  'wednesday-sshort-label': 'Mi',
  'thursday-sshort-label': 'Do',
  'friday-sshort-label': 'Fr',
  'saturday-sshort-label': 'Sa',
  'sunday-sshort-label': 'So',

  'monday-short-label': 'Mon',
  'tuesday-short-label': 'Die',
  'wednesday-short-label': 'Mit',
  'thursday-short-label': 'Don',
  'friday-short-label': 'Fre',
  'saturday-short-label': 'Sam',
  'sunday-short-label': 'Son',

  'monday-label': 'Montag',
  'tuesday-label': 'Dienstag',
  'wednesday-label': 'Mittwoch',
  'thursday-label': 'Donnerstag',
  'friday-label': 'Freitag',
  'saturday-label': 'Samstag',
  'sunday-label': 'Sonntag',

  'january-label': 'Januar',
  'february-label': 'Februar',
  'march-label': 'März',
  'april-label': 'April',
  'may-label': 'Mai',
  'june-label': 'Juni',
  'july-label': 'Juli',
  'august-label': 'August',
  'september-label': 'September',
  'october-label': 'Oktober',
  'november-label': 'November',
  'december-label': 'Dezember',

  'interval-name-year': 'Jahr',
  'interval-name-month': 'Monat',
  'interval-name-week': 'Woche',
  'interval-name-day': 'Tag',
  'interval-name-years': 'Jahre',
  'interval-name-months': 'Monate',
  'interval-name-weeks': 'Wochen',
  'interval-name-days': 'Tage',

  'select-input.no-options-label': 'Keine Optionen',
  'filter-input.label': 'Filter:',

  'no-data.label': 'Keine Daten zur Anzeige. Ändern Sie die Filter oder das ausgewählte Datum.',
  'no-data.label-mid': 'Keine Daten zur Anzeige. Ändern Sie das ausgewählte Datum.',
  'no-data.label-short': 'Keine Daten zur Anzeige.',
  'empty-list-label': 'Leere Liste.',
  'overlay.empty-device-label': 'Es wurden noch keine Geräte zu diesem Profil hinzugefügt.',
  'overlay.empty-profiles-label': 'Noch kein Profil hinzugefügt.',
  'overlay.empty-device.add-device-label': 'Gerät hinzufügen',

  'confirmation-modal.cancel-button': 'Abbrechen',
  'confirmation-modal.confirm-button': 'BESTÄTIGEN',
  'confirmation-modal.button-yes': 'Ja',
  'confirmation-modal.button-no': 'Nein',

  // Date Picker
  'date-picker.select-date-label': 'Datum auswählen',

  // Date Filter
  'date-filter.select-label': 'Bereich auswählen',
  'date-filter.this-month': 'Dieser Monat',
  'date-filter.this.week': 'Diese Woche',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Tag auswählen',
  'date-filter.from-label': 'Von: ',
  'date-filter.to-label': 'Bis: ',

  // Time Picker
  'time-picker.unlimied-label': 'Unbegrenzt',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Zeitbonus nur für heute hinzufügen',
  'time-picker.no-bonus': 'Kein Bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Bonus festlegen',
  'time-picker.add-bonus-button-cancel': 'Abbrechen',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Zeitraum',
  'profile-image-picker.girl-label': 'Mädchen',
  'profile-image-picker.boy-label': 'Junge',

  // Profile List
  'profile-list.remove-device-label': 'Gerät entfernen',
  'profile-list.move-device-label': 'Gerät zu einem anderen Profil verschieben',
  'profile-list.add-device-label': 'Gerät hinzufügen',

  'profile-list.add-profile-label': 'Profil hinzufügen',
  'profile-list.edit-profile-label': 'Profil bearbeiten',
  'profile-list.add-devices-label': 'Neues Gerät hinzufügen',
  'profile-list.remove-profile-label': 'Profil entfernen',
  'profile-list.remove-profile-disabled-tooltip': 'Um Ihr Profil zu löschen, müssen Sie zuerst alle mit dem Profil verbundenen Geräte entfernen.',
  'profile-list.title': 'Profilliste',

  // Profile Form
  'profile-form.full-name-label': 'Name',
  'profile-form.month-of-birth-label': 'Geburtsmonat',
  'profile-form.year-of-birth-label': 'Geburtsjahr',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Abbrechen',
  'profile-form-modal.create.submit-button': 'PROFIL HINZUFÜGEN',
  'profile-form-modal.create.title': 'Kinderprofil hinzufügen',
  'profile-form-modal.edit.skip-button': 'Abbrechen',
  'profile-form-modal.edit.submit-button': 'SPEICHERN',
  'profile-form-modal.edit.title': 'Kinderprofil bearbeiten',
  'profile-form-modal.max-profiles-error': 'Profilgrenze erreicht',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Gerät zu einem anderen Profil verschieben',
  'profile-move-device-modal.cancel-button': 'Abbrechen',
  'profile-move-device-modal.submit-button': 'Speichern',
  'profile-move-device-modal.select-profile-label': 'Profil auswählen',

  // CallLog
  'call-log.duration-label': 'Anrufdauer:',
  'call-log.seconds-label': 'Sek.',

  // Create Profile Page
  'create-profile-page.submit-button': 'PROFIL HINZUFÜGEN',
  'create-profile-page.skip-button': 'Abbrechen',
  'create-profile-page.title': 'Kinderprofil hinzufügen',
  'create-profile-page.description': 'Wer wird überwacht',

  // Add device page
  'add-device-page.close-button': 'SCHLIESSEN',
  'add-device-page.description': 'Wählen Sie die Art des Geräts zur Installation der App',
  'add-device-page.title': 'Installationsanleitung',
  'add-device-page.check': 'Handbuch überprüfen',
  'add-device-page.mobile-title': 'Android-Smartphone oder Tablet',
  'add-device-page.mobile-description': 'Android-Version 6 oder höher',
  'add-device-page.desktop-title': 'Windows-Desktop oder Laptop',
  'add-device-page.desktop-description': 'Windows-Version 7 oder höher',


  // Purchase Page
  'purchase-page.skip-label': 'Gehe zum Dashboard',
  'purchase-page.title': 'Wähle den besten Plan',
  'purchase-page.title.features': 'Alle Pläne bieten die folgenden Funktionen',
  'purchase-page.title.reviews': 'Warum unsere Benutzer uns lieben',
  'purchase-page.subtitle': 'Wenn das Programm nicht Ihren Erwartungen entspricht, erstatten wir die Zahlung',
  'purchase-page.plan-highlight': 'Bestes Preis-Leistungs-Verhältnis',
  'purchase-page.monthly': 'Monatlich',
  'purchase-page.select': 'Auswählen',
  'purchase-page.close': 'Schließen',
  'purchase-page.money-back.title': 'Ohne Risiko',
  'purchase-page.money-back.desc': 'Wenn Sie nicht zufrieden sind, erhalten Sie Ihr Geld zurück.',
  'purchase-page.header.check-1': 'Beste App zur Jugendschutzkontrolle',
  'purchase-page.header.check-2': 'Zeitlimit für Spiele, YouTube und Webseiten festlegen',
  'purchase-page.header.check-3': 'Hunderttausende zufriedener Kunden',
  'purchase-page.plan.year': 'Jahr',
  'purchase-page.plan.optimal': 'Bestes Preis-Leistungs-Verhältnis',


  'purchase-page.modal.2y-header': 'Spezialangebot!',
  'purchase-page.modal.2y-sub-header': 'Wählen Sie den <b>2-Jahres-Plan und sparen Sie</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Wählen Sie den <b>2-Jahres-Plan und sparen</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Schützen Sie bis zu {devices} Geräte',
  'purchase-page.modal.upgrade': 'Wählen Sie den Rabatt',
  'purchase-page.modal.no-thanks': 'Fortfahren mit dem 1-Jahres-Plan',


  'purchase-plan.features.title.feature_1': 'Zeitlimit für Apps und Spiele',
  'purchase-plan.features.title.feature_2': 'Website-Filterung',
  'purchase-plan.features.title.feature_3': 'Blockieren Sie Apps, Spiele und Websites',
  'purchase-plan.features.title.feature_4': 'YouTube Überwachung',
  'purchase-plan.features.title.feature_5': 'Live-Fernbildschirmansicht',
  'purchase-plan.features.title.feature_6': 'Fernzugriff',
  'purchase-plan.features.title.feature_7': 'Detaillierte Berichte',
  'purchase-plan.features.title.feature_8': 'Standort (nur Telefon)',
  'purchase-plan.features.title.feature_9': 'Prüfen Sie Nachrichten und Anrufe',
  'purchase-plan.features.title.feature_10': 'Priorisierter technischer Support',

  'purchase-plan.features.desc.feature_1': 'Legen Sie Zeitlimits für Apps, Spiele und Internetzugang fest. Zusätzlich können Sie festlegen, zu welchen Zeiten Ihr Kind Zugang dazu hat',
  'purchase-plan.features.desc.feature_2': 'Alle unangemessenen Websites werden blockiert. Das Programm verwendet einen Algorithmus, der die Inhalte der Website auf ungeeignetes Material analysiert. Es sammelt auch Informationen aus einer riesigen Datenbank mit unangemessenen Websites und Wörtern, die beide regelmäßig aktualisiert werden.',
  'purchase-plan.features.desc.feature_3': 'Blockieren Sie Apps und Websites, die sich negativ auf Ihre Kinder auswirken könnten. Auch jedes neu installierte Spiel benötigt Ihre Autorisierung.',
  'purchase-plan.features.desc.feature_4': 'Überwachen Sie im Dashboard alle Videos, die Ihr Kind auf YouTube anschaut. Blockieren Sie ein beliebiges Video oder blockieren Sie den Zugriff auf Videodienste vollständig.',
  'purchase-plan.features.desc.feature_5': 'Greifen Sie aus der Ferne auf einen Live-Feed vom Bildschirm Ihres Kindes zu und überprüfen Sie dessen Aktivitäten im Laufe des Tages. Dies ermöglicht es, den Inhalt, auf den Ihr Kind zugreift, genau im Auge zu behalten.',
  'purchase-plan.features.desc.feature_6': 'Sobald Sie sich angemeldet haben, können Sie von überall und von jedem Gerät aus auf das Eltern-Dashboard zugreifen. Im Dashboard können Sie Einstellungen anpassen, die Geräte der Kinder überwachen und Zeitlimits festlegen.',
  'purchase-plan.features.desc.feature_7': 'Im Eltern-Dashboard können Sie leicht die Aktivitäten eines Kindes auf seinem Computer oder Telefon überwachen, sehen, wie viel Bildschirmzeit sie verbracht haben, welche Websites sie besucht haben, und vieles mehr.',
  'purchase-plan.features.desc.feature_8': 'Überprüfen Sie den aktuellen Standort Ihres Kindes und vergangene Standorte auf der Karte. Sehen Sie genau, wo sich Ihr Kind an einem bestimmten Tag oder zu einer bestimmten Zeit aufgehalten hat.',
  'purchase-plan.features.desc.feature_9': 'Sie können ganz einfach die gesendeten und empfangenen Nachrichten Ihres Kindes, Telefonate und gespeicherte Kontakte überprüfen, indem Sie das Eltern-Panel verwenden.',
  'purchase-plan.features.desc.feature_10': 'Sollten Sie Hilfe benötigen, sind wir immer für Sie da. Es gibt auch viele Videoanleitungen, die Ihnen bei der Nutzung von {app} helfen.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Sehr effizient! Schützt mein Kind vollständig vor schädlichen Internetinhalten.',
  'purchase-plan.raitings.desc.raiting_2': 'Genau das, was ich gesucht habe! Ich habe für meine Töchter ein tägliches Limit von einer Stunde für den Zugang zum Internet festgelegt und Avosmart kümmert sich darum für mich.',
  'purchase-plan.raitings.desc.raiting_3': 'Ich empfehle Avosmart, weil es eine Vielzahl von Funktionen hat. Meine Kinder sind beim Surfen im Internet geschützt und ich kann kontrollieren, wie viel Zeit sie mit Spielen verbringen usw.',
  'purchase-plan.raitings.desc.raiting_4': 'Es war wichtig für meine Frau und mich, ein Auge auf die Social-Media-Gewohnheiten unserer Tochter zu haben. Avosmart hilft uns, das digitale und reale Leben unserer Tochter ausgeglichen zu halten.',
  'purchase-plan.raitings.desc.raiting_5': 'Mein Sohn hat früher zu viel Zeit im Internet verbracht, mit einem Laptop, Tablet, usw.. Mit den Berichten, die Avosmart liefert, weiß ich genau, welche Websites mein Sohn besucht und wie lange.',
  'purchase-plan.raitings.desc.raiting_6': 'Jetzt kann ich jederzeit den aktuellen Standort meines Sohnes überprüfen. Ein hervorragender Service!',


  'account-settings-page.title': 'Kontoeinstellungen',
  'account-settings-page.automatic-update': 'Automatische Aktualisierungen der Windows-Anwendung',
  'account-settings-page.timezone': 'Zeitzone: ',
  'account-settings-page.send-reports': 'Tägliche Aktivitätsberichte senden',


  'plans-info-page.title': 'Abonnementinformationen',
  'plans-info-page.plan': ' Plan:',
  'plans-info-page.max-devices': 'Maximale Anzahl an Geräten:',
  'plans-info-page.valid-till': 'Ablaufdatum:',

  'plans-info-page.your-plan': 'Ihr Plan:',
  'plans-info-page.subscription-information': 'Abonnementinformationen',
  'plans-info-page.subscription-created': 'Abonnement erstellt: ',
  'plans-info-page.subscription-current-period': 'Aktueller Abonnementzeitraum: ',
  'plans-info-page.subscription-renews-on': 'Ihr Plan wird verlängert am ',
  'plans-info-page.subscription-cancel-renewal': 'Verlängerung stornieren',
  'plans-info-page.subscription-not-renewed': 'Ihr Plan wird NICHT am Ende des aktuellen Zeitraums erneuert',
  'plans-info-page.subscription-enable-renewal': 'Verlängerung aktivieren',
  'plans-info-page.subscription-price': 'Abonnementpreis: ',
  'plans-info-page.subscription-price-amount': '{amount} alle {period}',


  'plans-info-page.upgrade-plan': 'Upgrade Ihres Plans',
  'plans-info-page.extend-lic': 'Verlängern Sie Ihren Plan',
  'plans-info-page.subscriptions-portal': 'Verwalten Sie Ihre Zahlungsmethoden',
  'plans-info-page.recent-payments': 'Letzte Transaktionen',
  'plans-info-page.date-of-payment': 'Transaktionsdatum:',
  'plans-info-page.transaction-status': 'Transaktionsstatus:',
  'plans-info-page.no-recent-payments': 'Keine neuesten Transaktionen',
  'plans-info-page.btn-close': 'Schließen',
  'plans-info-page.plan-devices': 'bis zu {devices} Geräte',
  'plans-info-page.plan-devices-types': 'Computer/Tablets/Telefone',


  'upgrade-page.title': 'Plan upgraden',
  'upgrade-page.devices': 'Geräte: ',
  'upgrade-page.valid-till': 'Ihr Plan ist gültig bis: ',
  'upgrade-page.confirm-upgrade': 'Ihr Plan wird verbessert, Ihnen wird {amount} berechnet',
  'upgrade-page.pay-now': 'Jetzt bezahlen {amount}',
  'upgrade-page.pay-then': 'Dann {amount} alle {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Abonnement kaufen',
  'purchase-checkout.up-to-devices': 'Bis zu {devices} Geräte',
  'purchase-checkout.subscribtion-price': '{amount} alle {period}',
  'purchase-checkout.subscribe': 'Abonnieren',
  'purchase-checkout.cancel': 'Stornieren',


  // Purchase Status Page
  'purchase-status-page.title': 'Zahlungsüberprüfung',
  'purchase-status-page.status-pending': 'Ausstehend',
  'purchase-status-page.status-completed': 'Abgeschlossen',
  'purchase-status-page.status-canceled': 'Storniert',
  'purchase-status-page.status-refund-finalized': 'Rückerstattung abgeschlossen',
  'purchase-status-page.status-wating-for-conf': 'Warte auf Bestätigung',
  'purchase-status-page.status-created': 'Erstellt',
  'purchase-status-page.status-unkown': 'Unbekannt',
  'purchase-status-page.status-error': 'Fehler',
  'purchase-status-pag.back-button': 'Zurück zur Startseite',
  'purchase-status-pag.invoice-link': 'Ich möchte eine Umsatzsteuerrechnung erhalten',

  // Login Page
  'login-page.brandline': 'Einloggen',
  'login-page.brandline2': 'Website-Filterung',
  'login-page.email-label': 'E-Mail-Adresse',
  'login-page.login-button': 'ANMELDEN',
  'login-page.password-label': 'Passwort',
  'login-page.recovery-link': 'Passwort vergessen?',
  'login-page.invalid-login-error': 'Die eingegebene E-Mail-Adresse oder das Passwort sind nicht gültig',

  // Register Page
  'register-page.create-account-label': 'Benötigen Sie ein Konto? Erstellen Sie ein neues Konto',
  'register-page.description': 'Erstellen Sie Ihr kostenloses Konto',
  'register-page.email-label': 'E-Mail-Adresse',
  'register-page.login-link-label': 'Haben Sie ein Konto? Anmelden',
  'register-page.marketing-checkbox-label': 'Ich stimme dem Erhalt von Marketing- und Werbematerialien zu',
  'register-page.password-label': 'Passwort',
  'register-page.phoneNumber-label': 'Telefonnummer',
  'register-page.terms-privacy-checkbox-label': 'Ich stimme der Datenschutzerklärung und den Nutzungsbedingungen zu',
  'register-page.register-button': 'REGISTRIEREN',
  'register-page.register-success-title': 'Erfolg',
  'register-page.register-success-message': 'Die Registrierung war erfolgreich. Überprüfen Sie Ihr Postfach, um die E-Mail zu bestätigen',
  'register-page.register-error-captcha': 'CAPTCHA-Fehler. Versuchen Sie es erneut.',
  'register-page.register-error-already-registered-label': 'Bereits registriert. Versuchen Sie sich anzumelden oder erinnern Sie sich an das Passwort.',
  'register-page.register-error-password-to-short-label': 'Passwort zu kurz.',

  // Forgot Password Page
  'register-page.strong-pass': 'Ihr Passwort ist zu einfach und kann von anderen leicht entschlüsselt werden. Ihr Passwort sollte Groß- und Kleinbuchstaben sowie Zahlen enthalten und mindestens 8 Zeichen lang sein. Sind Sie sicher, dass Sie ein einfaches Passwort verwenden möchten?',
  'forgot-password-page.headline-title': 'Passwort erinnern',
  'forgot-password-page.email-label': 'E-Mail-Adresse',
  'forgot-password-page.reset-button': 'PASSWORT ERINNERN',
  'forgot-password-page.login-link-label': 'Zurück zur Anmeldeseite',
  'forgot-password-page.success-title': 'Passwort zurücksetzen',
  'forgot-password-page.success-message': 'Überprüfen Sie Ihr Postfach für Anweisungen zum Zurücksetzen Ihres Passworts',
  'forgot-password-page.email-not-confirmed': 'Ihre E-Mail-Adresse ist nicht verifiziert. Passwort-Zurücksetzung ist nicht verfügbar.',

  // Update Password Page
  'update-password-page.headline-title': 'Ein neues Passwort einstellen',
  'update-password-page.warning-title': 'HINWEIS:',
  'update-password-page.warning-description': 'Das Festlegen eines neuen Passworts führt zum Verlust der zuvor gespeicherten Standortdaten. Die verbleibenden Daten gehen nicht verloren. ',
  'update-password-page.warning-agreement': 'Ich verstehe und akzeptiere den Verlust der Standortdaten.',
  'update-password-page.new-password': 'Neues Passwort:',
  'update-password-page.new-password-retype': 'Neues Passwort wiederholen:',
  'update-password-page.submit': 'Neues Passwort festlegen',
  'update-password-page.alert-weak-password': 'Ihr Passwort ist zu einfach und könnte von unbefugten Personen leicht erraten werden. Das Passwort sollte Großbuchstaben, Kleinbuchstaben, Zahlen enthalten und mindestens 8 Zeichen lang sein. Möchten Sie trotzdem ein einfaches Passwort verwenden? ',
  'update-password-page.alert-agreement': 'Die Akzeptanz des Datenverlusts ist erforderlich.',
  'update-password-page.error-message': 'Fehler! Das Festlegen eines neuen Passworts ist fehlgeschlagen. ',
  'update-password-page.success-message': 'Neues Passwort festgelegt',
  'update-password-page.unknow-error-message': 'Etwas ist schief gelaufen',
  'update-password-page.error-link': 'Falscher Link',
  'update-password-page.error-posswords-not-equal': 'Passwörter stimmen nicht überein',
  'update-password-page.error-posswords-length': 'Passwörter zu kurz',
  'update-password-page.error-wrong-code': 'Falscher Code',
  'update-password-page.register-success-title': 'Erfolg!',
  'update-password-page.register-success-message': 'Neues Passwort festgelegt.',

  // Error Page
  'not-found-page.title': 'Hoppla... es sieht so aus, als wäre ein Fehler aufgetreten!',
  'not-found-page.error-code-text': 'Fehlercode',
  'not-found-page.go-back-home-button': 'ZURÜCK ZUM START',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Ihr Plan endet am {date}. Bitte denken Sie daran, heute ein Abonnement abzuschließen',
  'panel-page.purchase-demo-alert': 'Sie nutzen derzeit die Testversion.',
  'panel-page.purchase-expired-alert': 'Sie nutzen derzeit eine abgelaufene Testversion. Kaufen Sie Abonnements, um fortzufahren',
  'panel-page.purchase-lic-expired-alert': 'Ihr Abonnement ist abgelaufen. Kaufen Sie Abonnements, um fortzufahren',
  'panel-page.email-alert': 'Sie haben Ihre E-Mail-Adresse noch nicht bestätigt',
  'panel-page.button-email-resend': 'Die E-Mail erneut senden',
  'panel-page.email-alert-resend': ' oder die E-Mail noch einmal senden.',
  'panel-page.email-alert-resend-title': 'Bestätigung',
  'panel-page.email-alert-resend-msg': 'E-Mail wurde gesendet!',
  'panel-page.profile-delete-description': 'Sind Sie sicher, dass Sie das ausgewählte Profil löschen möchten?',
  'panel-page.profile-delete-device-description': 'Sind Sie sicher, dass Sie das ausgewählte Gerät löschen möchten? Daten werden verloren gehen.',
  'panel-page.profile-delete-device-second-description': 'Der Schutz auf dem Gerät wird deaktiviert. Sind Sie sicher, dass Sie fortfahren möchten?',

  'panel-page.email-confirmation-overlay-close': 'Schließen',
  'panel-page.email-confirmation-overlay-postpone': 'Später',
  'panel-page.email-confirmation-overlay-resend': 'Die E-Mail erneut senden',
  'panel-page.email-confirmation-overlay-header': 'Ihre untenstehende E-Mail-Adresse ist noch nicht bestätigt',
  'panel-page.email-confirmation-overlay-desc': "Wenn Sie einen Fehler bei der oben genannten E-Mail-Adresse gemacht haben, lassen Sie es uns bitte sofort wissen. <br>Wenn Sie die E-Mail nicht erhalten haben, klicken Sie bitte auf 'erneut senden' und überprüfen Sie Ihre anderen E-Mail-Ordner (einschließlich Junk/Spam).",

  'panel-page.email-confirmation-code-success-header': 'Danke',
  'panel-page.email-confirmation-code-success-desc': 'E-Mail wurde bestätigt!',
  'panel-page.email-confirmation-code-header': 'Bitte geben Sie den 6-stelligen Bestätigungscode ein, der <br> an Ihre E-Mail-Adresse gesendet wurde.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Der eingegebene Code ist ungültig',
  'panel-page.email-confirmation-code-error': 'Bei der Überprüfung des Codes ist ein unerwarteter Fehler aufgetreten',

  // Summary Page
  'summary-page.title': 'Dashboard',
  'summary-page.top-apps.title': 'Top Apps',
  'summary-page.top-blocked.title': 'Top blockiert',
  'summary-page.top-domains.title': 'Am häufigsten besuchte Webseiten',
  'summary-page.top-search.title': 'Top Suche',
  'summary-page.access-disable': 'Vorübergehende Deaktivierung',
  'internet-page.access-time-limit-set-label': 'Tägliches Limit für den Internetzugang:',
  'internet-page.access-time-limit-description': 'Legen Sie tägliche Zeitlimits für jedes Gerät fest und wählen Sie, wie viele Stunden der Internetnutzung erlaubt sind.',
  'disable-access.protection-enabled': 'Schutz ist aktiv',
  'disable-access.protection-disabled-for': 'Schutz ist vorübergehend deaktiviert, {time} Minuten verbleiben',
  'disable-access.device-offline': 'Gerät nicht verfügbar',
  'disable-access.protection-status': 'Schutzstatus',


  // Internet Page
  'internet-page.headline-title': 'Webseiten',
  'internet-page.access-time-page-title': 'Zugriffszeit',
  'internet-page.settings-page-title': 'Einstellungen',
  'internet-page.statistics-page-title': 'Statistiken',
  'internet-page.searches-page-title': 'Suchvorgänge',

  'internet-page.statistics-page-www-ok': 'Genehmigte Webseite',
  'internet-page.statistics-page-www-ok-white': 'Sichere Webseite',
  'internet-page.statistics-page-www-timelimit': 'Webseite aufgrund von Zeitlimits blockiert',
  'internet-page.statistics-page-www-userdef': 'Webseite blockiert, Benutzerdefinitionen',
  'internet-page.statistics-page-www-content': 'Webseite blockiert, unangemessener Inhalt',


  'internet-page.white-black-list-page-title': 'Sicher / Blockierte Webseiten',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filter',
  'internet-page.settings-page.white-list-only-label': 'Zugriff nur auf Webseiten der sicheren Liste erlauben',
  'internet-page.settings-page.block-erotic-label': 'Zugriff auf erotische Webseiten blockieren',
  'internet-page.settings-page.block-offensive-label': 'Zugriff auf beleidigende, aggressive Webseiten blockieren',
  'internet-page.settings-page.block-file-sites-label': 'Zugriff auf Seiten, die das Herunterladen von Dateien aus dem Internet ermöglichen, blockieren',
  'internet-page.settings-page.block-video-label': 'Zugriff auf Videoportale blockieren',
  'internet-page.settings-page.block-social-label': 'Zugriff auf soziale Netzwerke blockieren',
  'internet-page.settings-page.block-doc-label': 'Das Herunterladen von Dokumenten blockieren',
  'internet-page.settings-page.block-exe-label': 'Das Herunterladen von Programmen blockieren',
  'internet-page.settings-page.excludeYT-label': 'Keine sichere YouTube-Suche erzwingen',
  'internet-page.settings-page.block-new-apps': 'Neue Anwendungen blockieren',
  'internet-page.settings-page.block-settings': 'Zugriff auf Einstellungen blockieren',
  'internet-page.settings-page.custom-msg.card-tile': 'Nachricht für blockierte Seiten',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Verwenden Sie eine personalisierte Nachricht für blockierte Webseiten',
  'internet-page.settings-page.save-settings-button-label': 'Einstellungen speichern',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Zur Liste hinzufügen:',
  'internet-page.lists.add-button-label': 'Hinzufügen',
  'internet-page.lists.remove-button-label': 'Löschen',
  'internet-page.lists.white-list.card-title': 'Sichere Liste',
  'internet-page.lists.black-list.card-title': 'Blockierte Liste',
  'internet-page.lists.remove-prefix': 'Die eingegebene Adresse beginnt mit "{prefix}", es wird empfohlen, nur den Domain-Namen hinzuzufügen. Möchten Sie "{prefix}" von der Adresse entfernen?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Besuchte Seiten',

  'internet-page.searches.searches-pages-card-title': 'Gesuchte Phrasen',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Zeitplan',
  'internet-page.access-time.schedule-legend-label': 'Zeitplan-Legende',
  'internet-page.access-time.schedule-internet-available-label': 'Internet verfügbar',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet gesperrt',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Anrufe & Textnachrichten (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Anrufe',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Textnachrichten (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Letzte Änderung:',
  'calls-text-messages-page.contacts-no-changes-label': 'Leer.',
  'calls-text-messages-page.contacts-phone-label': 'Tel.',
  'calls-text-messages-page.contacts-title': 'Kontakte',

  // Localization Page
  'localization-page.headline-title': 'Standort',
  'localization-page.headline-title-off': 'Ortungen, GPS-Tracking ist ausgeschaltet',
  'localization-page.map-hours-filter-label': 'Wählen Sie den Zeitbereich zur Ansicht des Standorthistorie',
  'localization-page.gps-tracking-on': 'GPS-Tracking aktiviert',
  'localization-page.gps-view-speed': 'Reisegeschwindigkeit anzeigen',
  'localization-page.map-loading-label': 'Karten laden...',
  'localization-page.map-no-data': 'KEINE DATEN',

  'localization-page.one-gps-was-off-since': 'Gerätestandortdienste seit {lastOffStr} ausgeschaltet',
  'localization-page.one-gps-was-off-for': 'Gerätestandortdienste für {elapsedStr} ausgeschaltet',
  'localization-page.many-gps-was-off-since': 'Gerätestandortdienste seit {lastOffStr} ausgeschaltet',
  'localization-page.many-gps-was-off-for': 'Gerätestandortdienste für {elapsedStr} ausgeschaltet',


  // Battery Page
  'battery-page.headline-title': 'Batterie',
  'battery-page.chart-description': 'Batteriestand',

  // Access Time Page
  'access-time-page.headline-title': 'Zugriffszeit',
  'access-time-page.nav-access-time-label-applications': 'Anwendungen',
  'access-time-page.nav-access-time-label-internet': 'Webseiten',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Liste der Videos',
  'youtube-page.no-videos': 'Keine Videos am ausgewählten Datum',
  'youtube-page.block-videoid': 'Dieses Video sperren',
  'youtube-page.block-channelid': 'Diesen Kanal sperren',
  'youtube-page.video-blocked': 'Gesperrt',

  // Screen Pages
  'screenview-page.headline-title': 'Bildschirmansicht',
  'screenview-page.reload-button': 'Neu laden',
  'screenview-page.chart-description': 'Aktuelle Bildschirmansicht',
  'screengrab-page.headline-title': 'Bildschirmaufnahme',
  'screengrab-page.chart-description': 'Bildschirmaufnahme',
  'screengrab-page.screen-save-time': 'Wie oft die Bildschirmaufnahme gemacht wird',
  'screengrab-page.screen-save-time-desc': 'Wählen Sie aus, wie oft der Bildschirmgriff aufgenommen wird, oder wählen Sie "deaktiviert", um keine Bildschirmgriffe aufzuzeichnen',
  'screengrab-page.screen-save-time-disabled': 'deaktiviert',
  'screengrab-page.no-screens-for-today': 'An diesem Tag wurden keine Bildschirmgriffe aufgenommen',
  'screengrab-page.no-screens-for-filter': 'Keine Bildschirme entsprechen den ausgewählten Kriterien',
  'screengrab-page.imgs-delete-description': 'Sind Sie sicher, dass Sie die ausgewählten Bilder löschen möchten?',
  'screengrab-page.imgs-delete-description-one': 'Sind Sie sicher, dass Sie das ausgewählte Bild löschen möchten?',
  'screengrab-page.imgs-delete-label': 'Löschen',
  'screengrab-page.imgs-delete-select': 'Zum Löschen auswählen',
  'screengrab-page.imgs-delete-selected': 'Ausgewählt:',
  'screengrab-page.imgs-delete-limit': 'Das Limit für ausgewählte Screenshots wurde erreicht.',
  'screengrab-page.imgs-filter': 'Filter:',
  'screengrab-page.imgs-filter-communicators': 'Kommunikatoren',
  'screengrab-page.imgs-filter-social': 'Soziale Medien',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Andere',

  'screes-pages.device-offline': ' Das Gerät ist offline',
  'screes-pages.device-deactivated': 'Der Schutz wurde vorübergehend deaktiviert',
  'screes-pages.device-timeout': 'Gerät hat nicht geantwortet',
  'screes-pages.device-screenoff': 'Der Bildschirm ist ausgeschaltet',
  'screes-pages.device-notallowed': 'Bildschirmaufnahme ist auf dem Gerät nicht erlaubt',
  'screes-pages.device-error': 'Ein Fehler ist aufgetreten',

  'lazy-image.img-delete-description': 'Sind Sie sicher, dass Sie das ausgewählte Bild löschen möchten?',

  // Applications Page
  'applications-page.headline-title': 'Anwendungen',
  'applications-page.nav-access-time-label': 'Zugriffszeit',
  'applications-page.nav-statistics-label': 'Zusammenfassung',
  'applications-page.nav-apps-list-label': 'Apps-Liste',
  'applications-page.chart-activity-by-day-label': 'Aktivität während des Tages',
  'applications-page.chart-hour-label': 'Stunde',
  'applications-page.chart-activity-by-hour-minutes-label': 'Aktivität während des Tages in Minuten',
  'applications-page.list-launched-count-label': 'Startzähler:',
  'applications-page.list-elapsed-time-label': 'Laufzeit:',
  'applications-page.introduction': 'Installierte Apps',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Zeitbereich planen',
  'applications-page.settings.schedule-legend-label': 'Zeitplan Legende',
  'applications-page.settings.schedule-blocked-label': 'Gesperrte Apps',
  'applications-page.settings.schedule-available-label': 'Verfügbare Apps',
  'applications-page.time-limit.description': 'Zeitlimit für bestimmte Apps festlegen. Wählen Sie aus, wie viele Stunden pro Tag die Nutzung der Anwendung erlaubt ist.',
  'applications-page.apps-headline-title': 'Verfügbare Apps',
  'applications-page.apps-first-seen-label': 'Erstmals gesehen:',
  'applications-page.apps-available-switch-label': 'Verfügbar',
  'applications-page.apps-not-available-switch-label': 'Nicht verfügbar',
  'applications-page.apps-schedule-switch-label': 'Geplant',
  'applications-page.apps-no-schedule-switch-label': 'Nicht geplant',
  'applications-page.apps-time-limit-switch-label': 'Zeitlimit',
  'applications-page.apps-no-time-limit-switch-label': 'Kein Zeitlimit',
  'applications-page.apps-show-uninstalled-label': 'Nicht installierte Anwendungen anzeigen',
  'applications-page.apps-show-apps-list': 'Wählen Sie zeitlich begrenzte Apps',

  'applications-page.used-count-zero-label': 'Nicht gestartet',
  'applications-page.used-count-one-label': '{value} Mal gestartet, Gesamtzeit: {totalTime}',
  'applications-page.used-count-many-label': '{value} Mal gestartet, Gesamtzeit: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sek',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sek',
  'applications-page.total-time-label': 'Gesamtzeit ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Aktivierungslink wurde bereits verwendet oder ist ungültig',
  'email-confirmation-page.successful-confirm': 'E-Mail wurde bestätigt. Danke!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Passwort ändern',
  'change-password-page.new-password-input-label': 'Neues Passwort',
  'change-password-page.new-password-repeated-input-label': 'Neues Passwort wiederholen',
  'change-password-page.current-password-input-label': 'Aktuelles Passwort',
  'change-password-page.back-button-label': 'Zurück',
  'change-password-page.change-button-label': 'ÄNDERN',
  'change-password-page.successful-change-label': 'Passwort wurde korrekt geändert',
  'change-password-page.wrong-current-password-label': 'Falsches aktuelles Passwort',
  'change-password-page.password-too-short-label': 'Neues Passwort ist zu kurz',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENÜ',
  'top-navigation.user-menu.account-label': 'Konto',
  'top-navigation.user-menu.sign-out': 'Abmelden',
  'top-navigation.user-menu.subscription-label': 'Abonnementplan',
  'top-navigation.user-menu.change-password-label': 'Passwort ändern',
  'top-navigation.user-menu.add-device-label': 'Gerät hinzufügen',

  // Sidebar
  'sidebar.menu-item.applications': 'Anwendungen',
  'sidebar.menu-item.calls-and-text-messages': 'Anrufe & Textnachrichten (SMS)',
  'sidebar.menu-item.internet': 'Webseiten',
  'sidebar.menu-item.localizations': 'Ortungen',
  'sidebar.menu-item.social-networks': 'Soziale Netzwerke',
  'sidebar.menu-item.summary': 'Dashboard',
  'sidebar.menu-item.battery': 'Batterie',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Bildschirmansicht',
  'sidebar.menu-item.screengrab': 'Bildschirmaufnahme',
  'sidebar.menu-item.access-time': 'Zugriffszeit',

  // Validator
  'phone-number.not-valid': 'Geben Sie eine gültige Telefonnummer ein',
  'input.required-label': 'Feld ist erforderlich',
  'input.select-required-label': 'Bitte auswählen',
  'email.not-valid-label': 'Geben Sie eine gültige E-Mail-Adresse ein',
  'domain-exist-label': 'Domain wurde hinzugefügt',
  'domain-exist-other-label': 'Domain existiert auf einer anderen Liste',
  'domain-not-valid': 'Domain ist nicht gültig',
  'password-not-match-label': 'Passwort stimmt nicht überein',

  // misc
  'button-buy-label': 'Wählen Sie einen Plan',

  'transactiion-status-0': 'Wartet',
  'transactiion-status-1': 'Abgeschlossen',
  'transactiion-status--1': 'Abgebrochen',
  'transactiion-status--2': 'Abgebrochen – Rückerstattung',
  'transactiion-status--3': 'Bestätigung ausstehend',
  'transactiion-status--100': 'Gestartet',
  'transactiion-status-other': 'Andere',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Erfolg',
  'reward-page.error-already-used': 'Dieser Code wurde bereits verwendet',
  'reward-page.error-invalid-code': 'Falscher Code',
  'reward-page.error-connection': 'Verbindungsfehler',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Werbe-Coupon einfordern',
  'reward-page.get-now': 'Fordern',
  'reward-page.lic-was-extended-by-days': 'Ihr Abonnementplan wurde um {rewardDays} Tage verlängert!',
  'reward-page.there-was-an-error': 'Leider ist ein Fehler aufgetreten.',
  'reward-page.lic-not-extended': 'Ihr Abonnementplan wurde nicht verlängert.',
  'reward-page.button-close': 'Schließen',

  // url item
  'url-item.copied': 'In die Zwischenablage kopiert',
  'url-item.copy-link': 'Link kopieren',

  // APK howto

  'apk-howto-google-play': 'Der Google Play Store begrenzt bestimmte Anwendungsberechtigungen, die für diese Funktion erforderlich sind.',
  'apk-howto-on-the-phone': 'Auf dem Telefon des Kindes mit {applite} installiert',
  'apk-howto-step1': 'Deinstallieren Sie {applite}, falls es zuvor über Google Play heruntergeladen wurde',
  'apk-howto-step2': 'Laden Sie die App direkt von unserer Website auf das Telefon des Kindes herunter',
  'apk-howto-step2B': 'oder scannen Sie den unten stehenden QR-Code auf dem Telefon des Kindes',
  'apk-howto-step3': 'Installieren Sie die heruntergeladene App auf dem Gerät',
  'apk-howto-tutorial': 'Eine Anleitung zur Installation der App auf einem Telefon oder Tablet',

  // Cookies

  'cookies-info-uses': 'Diese Website verwendet Cookies.',
  'cookies-info-privacy-policy': 'Mehr in der Datenschutzrichtlinie.',

  // Plan names

  'plan-name-demoExpired': 'Testphase abgelaufen',
  'plan-name-licExpired': 'Plan abgelaufen',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Test',
  'plan-name-minimal': 'Basis',
  'plan-name-regular': 'Regulär',
  'plan-name-premium': 'Premium',

  // agreements

  'agreement-will-inform-user': 'Ich erkläre, dass ich den Benutzer, auf dessen Gerät die App installiert wird, darüber informieren werde, dass seine Aktivitäten und sein Standort überwacht werden.',

  'agreement-privacy-policy': 'Ich erkläre, dass ich die <a href="{regulations}" target="_blank">Bedingungen</a> und die <a href="{privacy}" target="_blank">Datenschutzrichtlinie</a> gelesen und akzeptiert habe. Ich bestätige, dass ich über 18 Jahre alt bin und nur Kinder hinzufügen werde, die unter 18 Jahre alt sind und meine gesetzlichen Abhängigen sind.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Ich stimme zu, Marketinginhalte von {domain} bezüglich Werbeangebote an die von mir angegebene E-Mail-Adresse und Telefonnummer zu erhalten, sowie die Details an die in der Datenschutzrichtlinie genannten Werbepartner der Seite zum Zweck der digitalen Werbung weiterzuleiten. <a href="{marketing}" target="_blank">Mehr</a>',

}

export default {
  locale: 'de',
  name: 'Deutsch',
  data: appLocalData,
  messages
} as LanguageLocale
